import React, { ChangeEvent, useState, useEffect, Component, ReactElement } from "react";
import { Form, Button, Row, Col, Table, Nav, Tab } from 'react-bootstrap';
import { Container, Tabelas1, Tabelas } from './styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { ConsultaUsuario } from '../../interfaces/usuarioConsulta';
import { Subordinados } from '../../interfaces/subordinados';
import { ClienteNewconSituacaoCobranca } from "../../interfaces/clienteNewconSituacaoCobranca";
import api from '../../services/api';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useToast } from '../../hooks/toast';
import { DocumentosBloqueado } from '../../interfaces/documentosBloqueados';
import { BensVendedor } from '../../interfaces/bensVendedor';
import { BensVendaDetalhe } from '../../interfaces/bensVendaDetalhe'
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationBensVenda';
import { BensVenda } from '../../interfaces/bensVenda'
import { FiArrowDown, FiArrowUp, FiX, FiEdit2, FiEdit3, FiClock, FiArrowLeft } from 'react-icons/fi';
import { FaMapMarkerAlt, FaHome, FaFileDownload } from 'react-icons/fa';
import { MdOutlineAssignmentTurnedIn, MdLocalShipping, MdEmail, MdVisibility, MdDateRange, MdInfo, MdSwapHoriz, MdIso, MdDirectionsCar, MdOutlineTimer, MdOutlineShowChart } from 'react-icons/md';
import { FaList, FaListOl } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { BsListCheck, BsPrinterFill } from 'react-icons/bs';
import { RiSave3Fill } from 'react-icons/ri';
import { VendaBemDetalhe } from "../../interfaces/vendaBemDetalhe";
import { RendaNewcon } from "../../interfaces/rendaNewcon";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import NumberFormat from "react-number-format";
import { dadosPreVenda } from '../../mock/dadosPreVenda';
import { Header } from '../../components/Header';
import { DadosPreVendaTodos } from '../../hooks/dadosVenda';
import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import { dadosAssembleia } from '../../mock/calendarioAssembleia'
import { PlanoAgrupamento } from '../../interfaces/planoAgrupamentos'
import { fontSize } from "@mui/system";
import { borderRadius } from "polished";
import { NumeroAssembleia } from "../../interfaces/assembleias";
import InputMask from 'react-input-mask';
import InputAdornment from "@mui/material/InputAdornment";
import jsPDF from "jspdf";
import { logoGroscon } from '../../mock/logo';
import { ContatoConsulta } from '../../interfaces/contatoConsulta';
import itau from '../../assets/itau.png';
import "jspdf-barcode";
import { Boleto } from '../../components/Boleto'
import moment from 'moment';



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


interface bemSimples {
  codigoGrupo: number,
  codigoGrupoBem: number,
  credito: number,
  dataAssembleia: string,
  descricaoBem: string,
  idBem: number,
  idTabelaVendas: number,
  mediaLance: number,
  numeroMaximoCotas: number,
  parcela: number,
  parcelaSeguro: number,
  planoBasico: number,
  prazo: number,
  taxa: number,
}

const style = {
  position: 'absolute' as 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: 500,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 2,
  p: 4,

  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
};

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}

      getInputRef={inputRef}
      //style={{textAlign: 'center'}}
      style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center', color: 'var(--blue)' }}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator

      prefix={"R$ "}
      format={currencyFormatter}
    />
  );
}

function NumberFormatCustom1(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}

      getInputRef={inputRef}
      style={{ textAlign: 'center', fontSize: "22px" }}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalSeparator

      prefix={'% '}
      //suffix={'%'}
      format={"##.####"}
    />
  );
}

function currencyFormatter(value: any) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return amount
}

function valuetext(value: number) {
  return `${value}asdfasdfsdf`;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 9,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 'none',


        }}
      >
        <Typography
          variant="caption"
          component="div"

          color="var(--blue)"
          fontSize={9}
        >{`${(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel1(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', boxShadow: 'inset 0 0 1px 10px #E4F2FE', borderRadius: '50%' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 'none',
          borderRadius: 50

        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="#1976D2"
          fontSize={20}

        >{`${(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const fisica = {
  nomePrincipal: 'Nome',
  nomeSecundario: 'Sobrenome',
  data: 'Data de Nascimento',
  documentoPrincipal: 'CPF',
  mascaraPessoa: '999.999.999-99',
  documentoSecundario: 'RG',
  renda: "Renda Mensal",
  mascaraDinheiro: '#9.999,99'
}

const juridica = {
  nomePrincipal: 'Razão Social',
  nomeSecundario: 'Nome Fantasia',
  data: 'Data Constituição da Empresa',
  documentoPrincipal: 'CNPJ',
  mascaraPessoa: '99.999.999/9999-99',
  documentoSecundario: 'Inscrição Municipal ou Estadual',
  renda: "Faturamento Mensal",
  mascaraDinheiro: '999'
}

let ass1 = dadosAssembleia.vencimento01


const Simulacao: React.FC = (props: any) => {
  const { addToast } = useToast();
  const setDuplicados = new Set();
  const nome = localStorage.getItem('@Gscon:user')
  const { dadosVenda, setDadosVenda, alteraDados } = DadosPreVendaTodos();
  const [valueVendas, setValueVendas] = React.useState(0);
  const [usuarios, setUsuarios] = useState<ConsultaUsuario[]>([]);
  const [usuario, setUsuario] = useState<ConsultaUsuario>();
  const [subordinados, setSubordinados] = useState<Subordinados[]>([]);
  //const { DadosVenda } = props
  const [bensPorVendedor, setBensPorVendedor] = useState<BensVendedor[]>([]);
  const [bensPorVendedor1, setBensPorVendedor1] = useState<BensVenda[]>([]);
  const [grupos, setGrupos] = useState<number[]>([]);
  const [valorBens, setValorBens] = useState<BensVenda[]>([])
  const [maiorValorBem, setMaiorValorBem] = useState<number>(0)
  const [menorValorBem, setMenorValorBem] = useState<number>(0)
  const [maiorPrazoBem, setMaiorPrazoBem] = useState<number>(0)
  const [menorPrazoBem, setMenorPrazoBem] = useState<number>(0)
  const [listaSearchBem, setListaSearchBem] = useState<BensVenda[]>(valorBens)
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchBem.length / PER_PAGE);
  const _DATA = usePagination(listaSearchBem, PER_PAGE);
  const [filtroTipo, setFiltroTipo] = useState<number>(0)
  const [filtroGrupo, setFiltroGrupo] = useState<number>(0)
  const [filtroBem, setFiltroBem] = useState<string>("")
  const [idBemSelecionado, setIdBemSelecionado] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);
  const handleClose = () => (setDadosVenda(
    { ...dadosVenda, reducao: 0 }), setOpen(false));
  const [reducaoModal, setReducaoModal] = useState<number[]>([])
  const [bemSelecionado, setBemSelecionado] = useState<BensVendaDetalhe[]>([])
  let listaGeral: BensVenda[];
  const [show, setShow] = React.useState(false);
  const container = React.useRef(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [valorReducao, setValorReducao] = useState<number>(0);
  const [mostrarModal, setMostrarModal] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false)
  const [rendaTodos, setRendaTodos] = useState<RendaNewcon[]>([])
  const [rendaAprovada, setRendaAprovada] = useState<boolean>(false);
  const [mostraMensagem, setMostraMensagem] = useState<boolean>(false);
  const [mensagemRenda, setMensagemRenda] = useState<string>();
  const [parcelaSelecionada, setParcelaSelecionada] = useState<number>(0);
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [selectedValue1, setSelectedValue1] = React.useState('a');
  const [valuel, setValuel] = React.useState(0);
  const [assDia, setAssDia] = useState<number>(0);
  const [assMes, setAssMes] = useState<any>(0);
  const [planosAgrupamento, setPlanosAgrupamento] = useState<PlanoAgrupamento[]>([])
  const [planoAgrupamento, setPlanoAgrupamento] = useState<PlanoAgrupamento[]>([])
  const [assembleia, setAssembleia] = useState<NumeroAssembleia[]>([]);
  const [coeficiente, setCoeficiente] = useState(0);
  const [visualizar, setVisualizar] = useState<boolean>(false)
  const [excluirLance, setExcluirLance] = useState<boolean>(true)
  const [excluirComparativo, setExcluirComparativo] = useState<boolean>(true)
  const [contatos, setContatos] = useState<ContatoConsulta[]>([])

  const handleChangel = (event: React.SyntheticEvent, newValue: number) => {
    setValuel(newValue);

  };

  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDadosVenda({
      ...dadosVenda,
      renda: parseFloat(event.target.value) / 100,
      rendaReal: parseFloat(event.target.value),
      rendaFormatado: (parseFloat(event.target.value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    })
  };



  const alteraDadosSimulacao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDadosVenda({ ...dadosVenda, [e.target.name]: e.target.value })
  };


  const handleChangeOption1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue1(event.target.value);


    let dadosPlanos = planosAgrupamento.filter(item => item.sequenciaAgrupamento === parseInt(event.target.value))
    setPlanoAgrupamento(dadosPlanos)



  };

  useEffect(() => {
    buscaUsuarios();
    carregarPlanos();
    numeroAssembleia()
  }, [])

  async function numeroAssembleia() {
    const { status, data } = await api.get('/v1/assembleias')
    setAssembleia(data)
  }

  /*useEffect(() => {
    buscaDadosUsuario();
  }, [usuarios])*/

  /* useEffect(() => {
     listaSubordinados();
   }, [usuario])*/

  /*useEffect(() => {
     listaBensVendedores();
   }, [subordinados])*/

  /*useEffect(() => {
    carregaGrupos()
  }, [grupos])*/

  /* useEffect(() => {
     DadosFiltros()
   }, [valorBens])*/

  async function carregarPlanos() {
    const { data } = await api.get('/v1/planos-agrupamento')
    setPlanosAgrupamento(data)
  }


  function buscaUsuarios() {

    api.get('/v1/usuarios').then((response) => {
      const users: ConsultaUsuario[] = response.data
      const dadosUsuario = users.find(item => item.nome === nome)
      console.log('teste dados usuario')
      console.log(dadosUsuario)
      setUsuario(dadosUsuario)
      setDadosVenda({ ...dadosVenda, idVendedor: dadosUsuario?.dadoUsuario.id })
      setUsuarios(response.data)
      if (dadosUsuario?.dadoUsuario.listContato !== null && dadosUsuario?.dadoUsuario.listContato !== undefined) {
        setContatos(dadosUsuario.dadoUsuario.listContato)
      }

      api.get(`/v1/tabelas-venda-usuario/dado-usuario/${dadosUsuario?.dadoUsuario.id}/calculado`).then((response1) => {
        setBensPorVendedor(response1.data)
        console.log('vendedor')
        console.log(dadosUsuario?.dadoUsuario.id)
        console.log('tabela de vendas')
        console.log(response1)
        const teste: BensVendedor[] = response1.data
        const unico = teste[0].bens.filter((item) => {
          const duplicados = setDuplicados.has(item.idBem)
          setDuplicados.add(item.idBem)
          return !duplicados
        })
        const unicoOrdem = unico.sort(function (a, b) {
          if (a.idBem > b.idBem) {
            return 1
          }
          if (a.idBem < b.idBem) {
            return -1
          }
          return 0;
        })
        //setBensPorVendedor(response1.data)
        setBensPorVendedor1(unicoOrdem)
        setValorBens(response1.data[0].bens)
        setListaSearchBem(response1.data[0].bens)
        const dadosBens: BensVenda[] = response1.data[0].bens
        let grupos = dadosBens?.map(item => item.codigoGrupo)
        let gruposFiltro = grupos.filter(function (item, i) {
          return grupos.indexOf(item) === i;
        })
        let gruposOrdem = gruposFiltro.sort()
        setGrupos(gruposOrdem)

        let valoresBens = dadosBens?.map(item => item.credito)
        let maiorValorBem = Math.max(...valoresBens)
        let menorValorBem = Math.min(...valoresBens)
        setMaiorValorBem(maiorValorBem)
        setMenorValorBem(menorValorBem)

        let mesesBens = dadosBens?.map(item => item.prazo)
        let maiorPrazoBem = Math.max(...mesesBens)
        let menorPrazoBem = Math.min(...mesesBens)
        setMaiorPrazoBem(maiorPrazoBem)
        setMenorPrazoBem(menorPrazoBem)
      }).catch((error) => {
        alert('Ocorreu erro consulta dado-usuario')
      })
    }).catch((error) => {
      alert("ocorreu um erro na consulta Usuarios")
    })
    /*const { status, data } = await api.get('/v1/usuarios')
    const users:ConsultaUsuario[] = await data
    const dadosUsuario = await users.find(item => item.nome === nome)
    setUsuario(dadosUsuario)
    setDadosVenda({ ...dadosVenda, idVendedor: dadosUsuario?.dadoUsuario.id })
    setUsuarios(data)
    if (dadosUsuario !== null) {
      console.log()setGrupos
      let { data, status } = await api.get(`/v1/tabelas-venda-usuario/dado-usuario/${usuario?.dadoUsuario.id}/calculado`)
      setBensPorVendedor(data)
      console.log('chegou 334')
      console.log(data)
      const teste: BensVendedor[] = data
      const unico = teste[0].bens.filter((item) => {
        const duplicados = setDuplicados.has(item.idBem)
        setDuplicados.add(item.idBem)
        return !duplicados
      })
      console.log(unico)
      console.log(data)
      setBensPorVendedor(data)
      setBensPorVendedor1(unico)
      setValorBens(data[0].bens)
      setListaSearchBem(data[0].bens)
      const dadosBens:BensVenda[] = await data[0].bens

      let grupos = dadosBens?.map(item => item.codigoGrupo)
      let gruposFiltro = grupos.filter(function (item, i) {
        return grupos.indexOf(item) === i;
      })
      setGrupos(gruposFiltro)

      let valoresBens = dadosBens?.map(item => item.credito)
      let maiorValorBem = Math.max(...valoresBens)
      let menorValorBem = Math.min(...valoresBens)
      setMaiorValorBem(maiorValorBem)
      setMenorValorBem(menorValorBem)

      let mesesBens = dadosBens?.map(item => item.prazo)
      let maiorPrazoBem = Math.max(...mesesBens)
      let menorPrazoBem = Math.min(...mese/v1/dados-usuario/sBens)
      setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)
    }else{
      console.log('erro usuario')
    }
*/


  }

  function buscaDadosUsuario() {
    const dadosUsuario = usuarios?.find(item => item.nome === nome);
    setUsuario(dadosUsuario)
    setDadosVenda({ ...dadosVenda, idVendedor: dadosUsuario?.dadoUsuario.id })
  }

  /*async function listaSubordinados() {
    if (usuario !== null) {
      if (usuario?.dadoUsuario.cargo?.id !== 3) {
        let { data } = await api.get(`/v1/dados-usuario/${usuario?.dadoUsuario.id}/subordinados`)
        setSubordinados(data)
      } else {
        let { data } = await api.get('/v1/dados-usuario/')
        setSubordinados(data)
      }
    }
  }*/

  /*async function listaBensVendedores() {
    if (usuario !== null) {
      let { data, status } = await api.get(`/v1/tabelas-venda-usuario/dado-usuario/${usuario?.dadoUsuario.id}/calculado`)
      setBensPorVendedor(data)
      console.log('chegou 334')
      console.log(data)
      const teste: BensVendedor[] = data
      const unico = teste[0].bens.filter((item) => {
        const duplicados = setDuplicados.has(item.idBem)
        setDuplicados.add(item.idBem)
        return !duplicados
      })
      console.log(unico)
      console.log(data)
      setBensPorVendedor(data)
      setBensPorVendedor1(unico)
      setValorBens(data[0].bens)
      setListaSearchBem(data[0].bens)
    }
  }*/

  async function DadosFiltros() {
    let grupos = valorBens?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    setGrupos(gruposFiltro)

    let valoresBens = valorBens?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = valorBens?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  function handleSearchTodos() {

    setPage(1)
    _DATA.jump(1);

    setDadosVenda({ ...dadosVenda, visualizaBens: true })

    let listaGeral: BensVenda[];

    if (filtroTipo === 0) {
      listaGeral = valorBens;
      setListaSearchBem(listaGeral);
    } else {
      const lista: BensVenda[] = valorBens.filter(item => filtroTipo ? item.codigoGrupoBem === filtroTipo : item)
      listaGeral = lista
      setListaSearchBem(listaGeral);

    };

    if (filtroGrupo !== 0) {
      const lista: BensVenda[] = listaGeral.filter(item => filtroGrupo ? item.codigoGrupo === filtroGrupo : item)
      listaGeral = lista
      setListaSearchBem(listaGeral)

    };

    if (filtroBem !== "") {
      const lista: BensVenda[] = listaGeral.filter(item => filtroBem ? item.idBem === parseInt(filtroBem) : item)
      listaGeral = lista
      setListaSearchBem(listaGeral);
    };


    const lista: BensVenda[] = listaGeral.filter(item => value ? item.credito >= value[0] && item.credito <= value[1] : item)
    listaGeral = lista
    const lista2: BensVenda[] = listaGeral.filter(item => value1 ? item.prazo >= value1[0] && item.prazo <= value1[1] : item)
    listaGeral = lista2
    setListaSearchBem(listaGeral);


    setPage(1)

  }

  function insereGrupo(grupo: number) {
    const grupo1 = grupos.find(item => (
      item === grupo
    ))
    if (grupo1 === undefined)
      setGrupos([...grupos, grupo])

  }

  const [value, setValue] = React.useState<number[]>([20000, 1800000]);
  const [camposPessoa, setCamposPessoa] = React.useState(dadosVenda?.pessoa === "fisica" ? fisica : juridica);


  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const marks = [
    {
      value: { menorValorBem },
      label: { menorValorBem },
    },
    {
      value: { maiorValorBem },
      label: { maiorValorBem },
    },
  ];

  const [value1, setValue1] = React.useState<number[]>([20, 999]);

  const handleChange1 = (event: Event, newValue: number | number[]) => {
    setValue1(newValue as number[]);
  };

  const marks1 = [
    {
      value: { menorPrazoBem },
      label: { menorPrazoBem },
    },
    {
      value: { maiorPrazoBem },
      label: { maiorPrazoBem },
    },
  ];

  const [model, setModel] = useState({
    tipoPessoa: 'PESSOA_FISICA',
    vendedor: { id: 0 },
    documentoCliente: '',
    renda: 'Renda Mensal',
    valorRenda: 0
  })

  function handleChangeCheckPessoa(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'juridica') {
      setModel({ ...model, tipoPessoa: 'PESSOA_JURIDICA', renda: 'Faturamento Mensal', valorRenda: 0, documentoCliente: '' })
      setDadosVenda({ ...dadosPreVenda, pessoa: 'juridica', documento: "" })
      setMensagemRenda('')
      setCamposPessoa(juridica)
    } else {
      setCamposPessoa(fisica)
      setMensagemRenda('')
      setModel({ ...model, tipoPessoa: 'PESSOA_FISICA', renda: 'Renda', valorRenda: 0, documentoCliente: '' })
      //setDadosVenda('pessoa', 'fisica')
      setDadosVenda({ ...dadosPreVenda, pessoa: 'fisica', documento: "" })

    }
  }

  function handleChangeVendedor(e: ChangeEvent<HTMLSelectElement>) {
    setModel({
      ...model,
      vendedor: { id: parseInt(e.target.value) }
    })
    setDadosVenda({ ...dadosVenda, idVendedor: parseInt(e.target.value) })

  }

  function handleChangeBem(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroBem(e.target.value)
  }

  function handleChangeGrupo(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroGrupo(parseInt(e.target.value))

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.codigoGrupo === parseInt(e.target.value) : item)
      listaGeral = lista

    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    setDadosVenda({ ...dadosVenda, grupo: e.target.value })

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  function handleChangeTipo(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroTipo(parseInt(e.target.value))

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.codigoGrupoBem === parseInt(e.target.value) : item)
      listaGeral = lista

    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    setGrupos(gruposFiltro)

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)
  }

  function handleChangeBemInput(e: ChangeEvent<HTMLInputElement>) {
    setFiltroBem(e.target.value)
  }

  async function handleChangeInputCalculadora(e: ChangeEvent<HTMLInputElement>) {
    let valorLance = 0
    let valorLanceV = 0
    let percLance = ''
    let grupo = parseInt(dadosVenda.grupo||'0')
    let resultado = e.target.value

    if (!resultado) {
      resultado = ""
    }

    if (resultado === '222222333333444450') {
      resultado = ""
    }





    if (e.target.name === 'b2' && dadosVenda.valorCredito !== undefined) {

      let valorMaximo = 0
      if (grupo > 56) {
        valorMaximo = parseInt((dadosVenda.valorCredito / 100 * parseFloat(dadosVenda.numero || '1') * 100).toString())
      } else {
        valorMaximo = parseInt((dadosVenda.valorCredito / 100 * 100 * 100).toString())
      }

      let valorLanceEmbutido = dadosVenda.t_p3 || 0
      let percLanceEmbutido = dadosVenda.b3

      if (parseFloat(percLanceEmbutido || '0') > parseFloat(resultado)) {
        valorLanceEmbutido = 0
        percLanceEmbutido = '0'
      }

      if (resultado.length > 7) {
        return
      }

      percLance = resultado

      if (parseFloat(resultado) > 100) {

        let valorLance2 = percLance.substring(0, 2)
        if (parseInt(valorLance2) > valorMaximo) {
          percLance = valorMaximo.toString()
          addToast({
            type: 'info',
            title: 'Valor Máximo',
            description: `Percentual máximo é ${dadosVenda.numero}%, valor máximo ${(valorMaximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
          });
        } else {
          percLance = percLance.substring(0, 2) + '.'
        }
      }

      valorLance = parseInt((dadosVenda.valorCredito / 100 * parseFloat(percLance.replace(",",".")) * 100).toString())
      
      let valorRecursosProprios = ((valorLance - (valorLanceEmbutido)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let valorCreditoLiquido = ((dadosVenda.valorCredito * 100 - (valorLanceEmbutido)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })




      if (dadosVenda.b2 === percLance) {
      } else {
        setDadosVenda({
          ...dadosVenda,
          b2: percLance,
          t_p2: valorLance,
          t_p3: valorLanceEmbutido,
          b3: percLanceEmbutido,
          b4: valorRecursosProprios,
          b5: valorCreditoLiquido,
        })
      }
    }

    if (e.target.name === 't_p2' && dadosVenda.valorCredito !== undefined) {
      let valorLanceEmbutido = dadosVenda.t_p3 || 0
      let percLanceEmbutido = dadosVenda.b3


      if ((valorLanceEmbutido || 0) > parseFloat(resultado)) {
        valorLanceEmbutido = 0
        percLanceEmbutido = '0'
      }
      valorLanceV = parseInt(resultado)
      let valorMaximo = 0
      if (grupo > 56) {
        valorMaximo = parseInt((dadosVenda.valorCredito / 100 * parseFloat(dadosVenda.numero || '1') * 100).toString())
      } else {
        valorMaximo = parseInt((dadosVenda.valorCredito / 100 * 100 * 100).toString())
      }
      let valorLanceC = parseFloat(resultado) / 100
      let valorPercLance = (valorLanceC / dadosVenda.valorCredito * 100).toFixed(4)
      let valorRecursosProprios = ((parseInt(resultado) - (valorLanceEmbutido || 0)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let valorCreditoLiquido = ((dadosVenda.valorCredito * 100 - (valorLanceEmbutido || 0)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

      if (parseInt(resultado) > valorMaximo) {
        valorLanceV = valorMaximo
        valorRecursosProprios = ((valorMaximo - (valorLanceEmbutido || 0)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        if (grupo > 56) {
          valorPercLance = dadosVenda.numero?.toString() || ''
        } else {
          valorPercLance = '99.9999'
        }
        addToast({
          type: 'info',
          title: 'Valor Máximo',
          description: `Percentual máximo é ${dadosVenda.numero}%, valor máximo ${(valorMaximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
        });
      }


      if (dadosVenda.t_p2 === valorLanceV) {

      } else {

        setDadosVenda({
          ...dadosVenda,
          t_p2: valorLanceV,
          b2: valorPercLance,
          t_p3: valorLanceEmbutido,
          b3: percLanceEmbutido,
          b4: valorRecursosProprios,
          b5: valorCreditoLiquido,
        })

      }



    }
  }


  async function handleChangeInputCalculadora1(e: ChangeEvent<HTMLInputElement>) {
    let valorLance = 0
    let valorLanceV = 0
    let percLance = ''
    let percMax = 30
    let resultado = e.target.value
    let grupo = dadosVenda.grupo || 0

    if (dadosVenda.grupo === "67") {
      percMax = 20
    }

    if (!resultado) {
      resultado = ""
    }


    if (e.target.name === 'b3' && dadosVenda.valorCredito !== undefined) {
      let valorLanceEmbutido = dadosVenda.t_p2 || 0
      let percLanceEmbutido = dadosVenda.b2
      if (resultado.length > 7) {
        return
      }

      if (parseFloat(resultado) > parseFloat(percLanceEmbutido || '0')) {
        resultado = percLanceEmbutido || '0'
      }



      percLance = resultado


      if (dadosVenda.grupo === "67") {
        percMax = 20
      }


      if (parseInt(resultado) > percMax) {


        let valorLance2 = percLance.substring(0, 2)
        if (parseInt(valorLance2) > percMax) {
          percLance = percMax.toString()
          addToast({
            type: 'info',
            title: 'Valor e Percentual máximo',
            description: `Percentual máximo é ${percMax}%`
          });
        } else {
          percLance = percLance.substring(0, 2) + '.'
        }
      }

      if (resultado.length > 7) {
        return
      }


      valorLance = parseInt((dadosVenda.valorCredito / 100 * (parseFloat(percLance) * 100)).toString())
      let valorRecursosProprios = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let valorCreditoLiquido = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      if (resultado === "") {
        valorCreditoLiquido = ((dadosVenda.t_p2 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        valorRecursosProprios = ((dadosVenda.t_p2 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else {
        valorRecursosProprios = (((valorLanceEmbutido || 0) - (valorLance)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        valorCreditoLiquido = ((dadosVenda.valorCredito * 100 - (valorLance)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }



      if (dadosVenda.b3 === percLance) {
      } else {
        setDadosVenda({
          ...dadosVenda,
          b3: percLance,
          t_p3: valorLance,
          t_p2: valorLanceEmbutido,
          b2: percLanceEmbutido,
          b4: valorRecursosProprios,
          b5: valorCreditoLiquido,
        })
      }
    }

    if (e.target.name === 't_p3' && dadosVenda.valorCredito !== undefined) {
      valorLanceV = parseInt(resultado)
      let valorLanceEmbutido = dadosVenda.t_p2 || 0
      let percLanceEmbutido = dadosVenda.b2
      let valorMaximo = parseInt((dadosVenda.valorCredito / 100 * 30 * 100).toString())


      if (!resultado) {
        valorLanceV = 0
      }

      if (valorLanceV > valorMaximo) {
        let vm = valorMaximo

        if (vm > (dadosVenda.t_p2 || 500000000)) {
          vm = (dadosVenda.t_p2 || 500000000)
        }

        addToast({
          type: 'info',
          title: 'Valor e Percentual máximo',
          description: `O percentual máximo é 30%, e o valor máximo é ${(valorMaximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
        });
        return
      }


      let valorLanceC = parseFloat(resultado) / 100
      let valorPercLance = (valorLanceC / dadosVenda.valorCredito * 100).toFixed(4)
      let valorRecursosProprios = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let valorCreditoLiquido = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      if (resultado === "") {
        valorRecursosProprios = ((dadosVenda.t_p2 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        valorCreditoLiquido = ((dadosVenda.valorCredito / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else {
        valorRecursosProprios = (((dadosVenda.t_p2 || 1) / 100) - (parseInt(resultado)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        valorCreditoLiquido = ((dadosVenda.valorCredito - (parseInt(resultado)) / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

      }


      if (valorLanceV > (valorLanceEmbutido || 0)) {

        valorLanceV = valorLanceEmbutido || 0
        valorPercLance = percLanceEmbutido || '0'
      }

      if (valorLanceV > valorMaximo) {
        valorLanceV = valorMaximo
        valorPercLance = '30'
        addToast({
          type: 'info',
          title: 'Valor e Percentual máximo',
          description: `O percentual máximo é 30%, e o valor máximo é ${(valorMaximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
        });
      }

      if (dadosVenda.t_p3 === valorLanceV) {
      } else {
        setDadosVenda({
          ...dadosVenda,
          t_p3: valorLanceV,
          b3: valorPercLance,
          b4: valorRecursosProprios,
          b5: valorCreditoLiquido,
          t_p2: valorLanceEmbutido,
          b2: percLanceEmbutido,
        })

      }



    }
  }



  async function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

    if (e.target.name === "documentoCliente" && resultado.length === 11 && e.target.id === "CPF") {
      const result = TestaCPF(resultado)
      if (!result) {
        addToast({
          type: 'error',
          title: 'CPF Inválido',
          description: 'Por favor insira um CPF Válido!'
        });
        return;
      }
    }

    setModel({
      ...model,
      [e.target.name]: resultado
    });

    setDadosVenda({ ...dadosVenda, documentoFormatado: e.target.value, documento: resultado })

    //buscaRendaCPF
    let response = await api.get(`/v1/parcelas-calculo-renda-newcon`)
    setRendaTodos(response.data)

  }

  async function handleChangeValor(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");


  }

  function TestaCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  async function confereCPF() {
    let newValue = new Date();
    let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
    if (model.documentoCliente.length > 9) {
      let { data } = await api.get(`/v1/documentos-bloqueados/documento/${model.documentoCliente}`)
      let bloqueados: DocumentosBloqueado[] = data
      const valido = bloqueados.map(item => item.status === "BLOQUEADO")
      if (valido.length > 0) {
        setDadosVenda({ ...dadosVenda, bloqueado: true })
        addToast({
          type: 'error',
          title: 'Bloqueio por Situação',
          description: 'É necessário entrar em contato com a administradora!'
        });
        return
      }
      let response = await api.get(`/v1/contratos-newcon/documento/${model.documentoCliente}`)
      if (response.data.length > 0) {
        let dados: ClienteNewconSituacaoCobranca[] = response.data;
        let situacaoCobranca = dados.filter(filtroPorSituacao)
        if (situacaoCobranca.length > 0) {
          const response = await api.post(`/v1/documentos-bloqueados`,
            {
              "documento": model.documentoCliente,
              "motivo": "Situação de Cobrança",
              "dataBloqueio": DataFormatada,
              "dataValidade": "",
              "status": "BLOQUEADO",

            })
          setDadosVenda({ ...dadosVenda, bloqueado: true })
          addToast({
            type: 'error',
            title: 'Bloqueio por Situação',
            description: 'É necessário entrar em contato com a administradora!'
          });


        }
      } else {
        setDadosVenda({ ...dadosVenda, bloqueado: false })
      }
    }
  }

  function filtroPorSituacao(x: ClienteNewconSituacaoCobranca) {
    if (x.codigoSituacao === 'J00' || x.codigoSituacao === 'J01' || x.codigoSituacao === 'JCO')
      return x
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  async function selecionaBem(bem: BensVenda) {
    const dia = parseInt(bem.dataAssembleia.substring(8, 10))
    setAssDia(parseInt(bem.dataAssembleia.substring(9, 11)))
    setAssMes(parseInt(bem.dataAssembleia.substring(5, 7)))

    if (dia < 19) {
      ass1 = dadosAssembleia.vencimento02
    } else {
      ass1 = dadosAssembleia.vencimento01
    }
    setDadosVenda({ ...dadosVenda, bemSelecionado: bem })
    let indice = "IPCA"
    let tipoBemDescricao = "AUTO"
    if (bem.codigoGrupoBem === 9) {
      indice = "INCC"
      tipoBemDescricao = "IMÓVEL"
    } else if (bem.codigoGrupoBem === 1) {
      indice = "IPCA"
      tipoBemDescricao = "CAMINHÃO"
    } else {
      indice = "IPCA"
      tipoBemDescricao = "AUTO"
    }
    //setModelBem(bem)
    let response = await api.get(`/v1/bens-tabela-venda/calculado?idTabela=${bem.idTabelaVendas}&idBem=${bem.idBem}&parcela=${bem.prazo}`)
    let grupos: BensVendaDetalhe[] = response.data
    let alinhados = grupos.sort(function compare(a, b) {
      if (a.adesao < b.adesao) return -1;
      if (a.adesao > b.adesao) return 1;
      return 0
    })
    setBemSelecionado(alinhados)
    let reducao = grupos.map(item => item.reducao)
    let reducaoFiltro = reducao.filter(function (item, i) {
      return reducao.indexOf(item) === i;
    })
    setReducaoModal(reducaoFiltro)

    let dataD = Date.now()
    let dataF = new Date(dataD)
    let dataDiaf = (`${dataF.getFullYear()}-${dataF.getMonth() + 1}-${dataF.getDate()}`)

    let juros = 1.5 / 100
    if (bem.codigoGrupoBem === 9) {
      juros = 0.8 / 100
    } else {
      juros = 1.5 / 100
    }

    const numAss = assembleia.find(item => item.codigoGrupo === bem.codigoGrupo && item.dataAssembleia === bem.dataAssembleia)
    let percentualMaximoLanceF = 0
    let percentualMaximoLance4 = ''
    if (numAss !== undefined) {
      let percentualMaximoLance = 100 - (100 / bem.planoBasico * numAss.numeroAssembleia)
      percentualMaximoLance4 = percentualMaximoLance.toFixed(4)
      percentualMaximoLanceF = parseFloat(percentualMaximoLance4)
    }


    let coeficienteFinanciamento = (juros / (1 - (1 / ((1 + juros) ** bem.prazo))))
    setCoeficiente(coeficienteFinanciamento)

    let valorFinanciamento = (bem.credito * coeficienteFinanciamento)
    let valorFinanciamentoF = valorFinanciamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    let diferencaParcelas = valorFinanciamento - bem.parcela
    let diferencaParcelasF = diferencaParcelas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    let diferencaTotal = diferencaParcelas * bem.prazo
    let diferencaTotalF = diferencaTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    let percentualDif = (((bem.parcela * bem.prazo) - (valorFinanciamento * bem.prazo)) / (bem.parcela * bem.prazo) * 100) * -1
    let percentualDifF = Math.round(percentualDif * 100) / 100
    let percentualIcone = (20 / 100 * percentualDif) + 'px'

    let dataDiaff = dataDiaf.replace(/-/g, '/').replace('T', ' ');


    setDadosVenda(
      {
        ...dadosVenda,
        codB: bem.idBem,
        descricaoBem: bem.descricaoBem,
        valorCredito: bem.credito,
        valorCreditoF: bem.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        prazo: bem.prazo,
        indiceCorrecao: indice,
        tipoBem: bem.codigoGrupoBem.toString(),
        descTipo: tipoBemDescricao,
        grupo: bem.codigoGrupo.toString(),
        planoBasico: bem.planoBasico,
        numMax: bem.numeroMaximoCotas,
        taxaAdministracao: bem.taxa,
        dataAdesaoF: dataDia(),
        dataAdesao: new Date(dataDiaff).toISOString(),
        primeiraAssF: formatData(bem.dataAssembleia),
        primeiraAssembleia: bem.dataAssembleia,
        mediaLance: bem.mediaLance,
        sequenciaAgrupamento: 0,
        idTabela: bem.idTabelaVendas,
        nomePai: (bem.taxa / bem.prazo).toFixed(2),
        valorParcela: bem.parcela,
        valorParcelaFormatado: bem.parcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        endereco: (((bem.credito / bem.prazo)) + ((bem.credito * (10.50 * 0.01)) / bem.prazo)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        bairro: ((((bem.credito / bem.prazo)) + ((bem.credito * (10.50 * 0.01)) / bem.prazo)) - bem.parcela).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        complemento: (((((bem.credito / bem.prazo)) + ((bem.credito * (10.50 * 0.01)) / bem.prazo)) - bem.parcela) * bem.prazo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        cidade: valorFinanciamentoF,
        uf: diferencaParcelasF,
        apto: diferencaTotalF,
        codBanco: percentualDifF,
        naturalCidade: percentualIcone,
        numero: percentualMaximoLance4,
        t_p1: percentualMaximoLanceF,
        b1: 'livre',
        b4: (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b5: bem.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        t_p2: 0,
        t_p3: 0,
        b2: '0',
        b3: '0'

      })

  }

  function mostrar(seq: number) {
    if (mostrarModal === seq) {
      setVisible(visible === true ? false : true)
    } else {
      setVisible(true)
      setMostrarModal(seq)
    }
  }

  async function handleChangeCheckReducao(e: ChangeEvent<HTMLInputElement>) {
    setValorReducao(parseInt(e.target.value))
    setDadosVenda({ ...dadosVenda, reducao: parseInt(e.target.value) })
    setBemSelecionado(bemSelecionado)

    bemSelecionado.map(item => {
      if (item.reducao === parseInt(e.target.value) && item.parcelaInicial === 1 && dadosVenda.valorCredito !== undefined && item.adesao === 0) {
        selecionaBemFinal(item)
        setSelectedValue1(item.sequenciaAgrupamento.toString())
        let dadosPlanos = planosAgrupamento.filter(item2 => item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
        setPlanoAgrupamento(dadosPlanos)
      }
    })
  }

  function dataDia() {
    let dataDia = Date.now()
    let dataAss = new Date(dataDia)
    let DataFormatada = ((adicionaZero(dataAss.getDate())) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function limparTodos() {
    setFiltroBem("")
    setFiltroGrupo(0)
    setFiltroTipo(0)
    setValue([0, 1800000])
    setValue1([0, 1800000])
    setListaSearchBem(valorBens)
    setDadosVenda({ ...dadosVenda, grupo: "" })
  }

  async function selecionaBemFinal(bem: VendaBemDetalhe) {
    if (dadosVenda.valorCredito !== null) {



      let valorFinanciamento = ((dadosVenda.valorCredito || 1) * coeficiente)
      let valorFinanciamentoF = valorFinanciamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let diferencaParcelas = valorFinanciamento - (dadosVenda.valorParcela || 1)
      let diferencaParcelasF = diferencaParcelas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let diferencaTotal = diferencaParcelas * (dadosVenda.prazo || 1)
      let diferencaTotalF = diferencaTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let percentualDif = ((((dadosVenda.valorParcela || 1) * (dadosVenda.prazo || 1)) - (valorFinanciamento * (dadosVenda.prazo || 1))) / ((dadosVenda.valorParcela || 1) * (dadosVenda.prazo || 1)) * 100) * -1
      let percentualDifF = Math.round(percentualDif * 100) / 100
      let percentualIcone = (20 / 100 * percentualDif) + 'px'

      setDadosVenda({
        ...dadosVenda,
        sequenciaAgrupamento: bem.sequenciaAgrupamento,
        valorParcelaFormatado: bem.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorParcela: bem.valorParcela,
        reciboPrimeiraParcela: bem.valorParcela * 100,
        reciboPrimeiraParcelaFormatado: bem.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorParcelaComSeguroFormatado: bem.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorParcelaComSeguro: bem.valorParcelaComSeguro * 100,
        adesao: bem.adesao,
        reducao: bem.reducao,
        //cidade: valorFinanciamentoF,
        //uf: diferencaParcelasF,
        //apto: diferencaTotalF,
        //codBanco: percentualDifF,

      })
    }


  }

  /*
    
  
    function Bem() {
      if (dadosVenda.sequenciaAgrupamento > 0) {
        setOpen(false)
      } else {
        addToast({
          type: 'error',
          title: 'Atenção',
          description: 'Selecione uma adesão'
        });
      }
  
    }
  
    function cancelaBem() {
      setDadosVenda({...dadosVenda, 
        sequenciaAgrupamento: 0,
        reducao: 0,
      })
      setValorReducao(0)
      setOpen(false)
      setMensagemRenda('')
      setSelectedValue('a')
      setSelectedValue1('a')
  
    }
  
    function fecharBem() {
      setDadosVenda({...dadosVenda, 
        sequenciaAgrupamento: 0,
        reducao: 0,
      })
      setValorReducao(0)
      setOpen(false)
      setMensagemRenda('')
    }
  
  
    
  
    
  
    
  
  
    function apenasNumeros(x:string) {
      /*var numsStr = x.replace(",",".");
      var y = numsStr.replace("R$ ", "");
      if(numsStr.length > 3){
        y = numsStr.slice(3,9999)
      }
     */
  //return parseFloat(x);
  //}
  /*
    async function validaRenda(){
      if(dadosVenda.renda > 0){
      const lista: RendaNewcon[] = rendaTodos.filter(item => model.documentoCliente ? item.cgcCpfCliente === model.documentoCliente : item)
      const valores = lista?.map(item => item.parcela)
      let totalNewcon = 0;
      if(valores.length > 0){
        totalNewcon = valores?.reduce((total, numero)=>total+numero)
      }
  
      const valor:number = dadosVenda.valorParcela || 0;
      let totalGeral = 0
      
      if(model.tipoPessoa === 'PESSOA_FISICA'){
        if(valor > 0){
          totalGeral = ((totalNewcon+valor)*3)
        }
      }else{
        if(valor > 0){
          totalGeral = totalNewcon+valor;
        }
      }
      let mensagem = ""
  
      if(totalNewcon > 0){
        mensagem = `cliente tem consórcio Groscon com parcelas no total de ${totalNewcon.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}.`
      }
      
      if(model.tipoPessoa === 'PESSOA_FISICA'){
      if(dadosVenda.renda > totalGeral){
        setDadosVenda({...dadosVenda, rendaAprovada: true})
  
        addToast({
          type: 'success',
          title: 'Renda Aprovada',
          description: 'Cliente apto a fazer a cota!'
        });
        setMensagemRenda('')
      }else{
        setMensagemRenda(`A renda tem que ser maior que ${totalGeral.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
        ${mensagem}
        `)
        addToast({
          type: 'error',
          title: 'Renda Inválida',
          description: `A renda tem que ser maior que ${totalGeral.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
          ${mensagem}
          `
        });
      }}else if(model.tipoPessoa === 'PESSOA_JURIDICA'){
        if((dadosVenda.renda/10) > totalGeral){
          setDadosVenda({...dadosVenda, rendaAprovada: true})
          addToast({
            type: 'success',
            title: 'Renda Aprovada',
            description: 'Cliente apto a fazer o cota!'
          });
          setMensagemRenda('')
        }else{
          setMensagemRenda(`A renda tem que ser maior que ${(totalGeral*10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
          ${mensagem}
          `)
          addToast({
            type: 'error',
            title: 'Renda Inválida',
            description: `A renda tem que ser maior que ${(totalGeral*10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
            ${mensagem}
            `
          });
        }
      }
      }else{
        setMensagemRenda('')
        addToast({
          type: 'error',
          title: 'Renda Inválida',
          description: `Digite uma renda Válida`
      })}
      
    }
  
    
  
    async function passaFase1(){
      const tudo = JSON.stringify(dadosVenda)
      const teste = JSON.parse(tudo)
      if(dadosVenda?.id > 0){
        const response = await api.put(`v1/pre-vendas/${dadosVenda.id}`,{
          idVendedor: dadosVenda.idVendedor,
          idTabela: dadosVenda.idTabela,
          documento: dadosVenda.documento,
          dataCriacao: dadosVenda.dataAdesao,
          etapa: 1,
          conteudo: tudo,
          dataExportacao: dadosVenda.dataAdesao,
        })
  
        if(response.status === 201){
          setDadosVenda({
            ...dadosVenda, 
            id: response.data[0].id,
            etapa2: false,
          
          })}
  
          setValueVendas(1)
      }else{
        const response = await api.post(`v1/pre-vendas`,{
          idVendedor: dadosVenda.idVendedor,
          idTabela: dadosVenda.idTabela,
          documento: dadosVenda.documento,
          dataCriacao: dadosVenda.dataAdesao,
          etapa: 1,
          conteudo: tudo,
          dataExportacao: dadosVenda.dataAdesao,
        })
  
        if(response.status === 201){
          setDadosVenda({
            ...dadosVenda, 
            id: response.data[0].id,
            etapa2: false,
          
          })
  
          setValueVendas(1)
        }
      }
      
  
    }
  
    function teclaEnter(){
      validaRenda()
    }
   
  
    function handleteste(e: ChangeEvent<HTMLInputElement>){
      var resultado = e.target.value;
      resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("R$ ", "");
      resultado = resultado.replaceAll(",",".")
  
  
    }*/

  function teste() {

  }

  function renderCaracteristica(title: string, text: string, icon: ReactElement) {
    return <Col>
      <p className="title-carac">{title}</p>
      <div style={{ display: 'flex', flexDirection: "row" }}>
        {icon}
        <p className="text-carac">{text}</p>
      </div>
    </Col>
  }

  function retornoFinanciamento(vbem: number, vprazo: number) {
    let juros = 1.5 / 100
    const bem = vbem || 0
    if (dadosVenda.descTipo === 'IMÓVEL') {
      juros = 0.8 / 100
    } else {
      juros = 1.5 / 100
    }

    var parcelas = vprazo || 0


    var coeficienteFinanciamento = (juros / (1 - (1 / ((1 + juros) ** parcelas))))
    var valorParcela = (bem * coeficienteFinanciamento)

    return valorParcela
  }

  function voltar() {
    setDadosVenda({
      ...dadosVenda, codB: 0, sequenciaAgrupamento: 0,
      reducao: 0,
    })
    setValuel(0)
    setValorReducao(0)
    setOpen(false)
    setMensagemRenda('')
    setSelectedValue('a')
    setSelectedValue1('a')
  }

  function alteraTipoLance(tipo: string) {

    let fixo = '30'
    if (dadosVenda.grupo === "67") {
      fixo = '20'
    }

    let lanceFixo = parseFloat(dadosVenda.numero || '31')
    if (lanceFixo < 30) {
      addToast({
        type: 'error',
        title: 'Percentual Máximo do Grupo é menor que 30%',
        description: `Valor máximo é 100%`
      });
    }
    let valorMaximo = parseInt(((dadosVenda.valorCredito || 1) / 100 * parseInt(fixo) * 100).toString())
    if (tipo === "fixo") {
      setDadosVenda({
        ...dadosVenda,
        b2: fixo,
        t_p2: valorMaximo,
        t_p3: 0,
        b3: '0',
        b4: (valorMaximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b5: (dadosVenda.valorCredito || 1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b1: tipo
      })
    } else {
      setDadosVenda({
        ...dadosVenda,
        b2: '0',
        t_p2: 0,
        t_p3: 0,
        b3: '0',
        b4: (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b5: (dadosVenda.valorCredito || 1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b1: tipo

      })
    }

  }

  function limparCalculadora() {
    setDadosVenda({
      ...dadosVenda,

      b3: '0',
      t_p3: 0,
      b2: '0',
      t_p2: 0,
      b4: (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      b5: ((dadosVenda.valorCredito || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    })
  }

  function excluirLance1() {
    excluirLance ? setExcluirLance(false) : setExcluirLance(true)
  }

  function excluirComparativo1() {
    excluirComparativo ? setExcluirComparativo(false) : setExcluirComparativo(true)
  }

  function visualizarSimulacao() {
    if (!visualizar) {
      setVisualizar(true)
    } else (
      setVisualizar(false)
    )

    setExcluirComparativo(true)
    setExcluirLance(true)
  }

  function salvar() {
    /* const tudo = JSON.stringify(DadosVenda)
     if (DadosVenda?.id > 0) {
       const response = await api.put(`v1/simulacoes/${DadosVenda.id}`, {
         idVendedor: DadosVenda.idVendedor,
         idTabela: DadosVenda.idTabela,
         documento: DadosVenda.documento,
         dataCriacao: DadosVenda.dataAdesao,
         etapa: 1,
         conteudo: tudo,
         dataValidade: DadosVenda.dataAdesao,
       })
 
       if (response.status === 201) {
         setAlteraDados({
           ...DadosVenda
         })
       }
     } else {
       const response = await api.post(`v1/simulacoes`, {
         idVendedor: DadosVenda.idVendedor,
         idTabela: DadosVenda.idTabela,
         documento: DadosVenda.documento,
         dataCriacao: DadosVenda.dataAdesao,
         etapa: 1,
         conteudo: tudo,
         dataValidade: DadosVenda.dataAdesao,
       })*/
  }



  const print = (tipo: string) => {

    if (dadosVenda.emailCom1 === "" || dadosVenda.emailCom2 === "" || dadosVenda.emailCom3 === "" || dadosVenda.emailCom4 === "") {
      addToast({
        type: 'info',
        title: 'Preencha todos campos do cliente!',
        description: "Preencher todos os campos do cliente para impressão!"
      });
      return
    }

    let continua: boolean = true;
    let continua2: boolean = true;
    let continua3: boolean = true;
    let continua4: boolean = true;
    const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')


    //const string = renderToString(<Prints />);
    const pdf = new jsPDF("p", "mm", "a4");
    
    pdf.setFontSize(10);
      pdf.setFont("times", "normal");
    pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
    pdf.text(`USUÁRIO: ${nome?.toUpperCase()}`, 200, 8, { align: 'right' });
    // console.log(dados)
    contatos.map(function (item) {
      if (item.tipoContato !== "EMAIL" && continua) {
        continua = false;
        return pdf.text(`TELEFONE ${item.valor || '(16) 3707-5500'}`, 200, 12.5, { align: 'right' });
      }
      if (item.tipoContato === "EMAIL" && continua2) {
        continua2 = false;
        return pdf.text(`E-MAIL: ${item.valor.toUpperCase()}`, 200, 17, { align: 'right' });
      }
    })
    if (continua)
      pdf.text(`TELEFONE (16) 3707-5500'}`, 200, 13, { align: 'right' });

    pdf.text(`DATA : ${dataAtual}`, 200, 22, { align: 'right' });
    //pdf.table(23, 58, generateData(100), headers ,{ autoSize: true });
    pdf.line(10, 25, 200, 25); // horizontal line
    pdf.setFontSize(15);
    pdf.setFont("arial", "bold")
    pdf.text("SIMULAÇÃO", 105, 33, { align: 'center' });
    pdf.line(10, 37, 200, 37);
    //pdf.table(23, 58, generateData(1), headers ,{ autoSize: true });
    //window.open(pdf.output('datauristring'));
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.setDrawColor(0);
    pdf.setFillColor(255, 255, 255);
    pdf.roundedRect(10, 40, 190, 32, 1, 1, "FD");
    pdf.setDrawColor(0);
    pdf.setFillColor(190, 190, 190)
    pdf.setDrawColor(190, 190, 190)
    pdf.roundedRect(15, 44, 120, 10, 2, 2, "FD");
    pdf.roundedRect(138, 44, 58, 10, 2, 2, "FD");
    pdf.roundedRect(15, 58, 181, 10, 2, 2, "FD");
    pdf.text(`Nome:${dadosVenda.emailCom1?.toUpperCase()}`, 20, 50, { align: 'left' });
    pdf.text(`Telefone ${dadosVenda.emailCom2.toUpperCase()} ${dadosVenda.emailCom3.toUpperCase()} `, 180, 50, { align: 'right' });
    pdf.text(`E-mail: ${dadosVenda.emailCom4.toUpperCase()}`, 20, 64, { align: 'left' });

    pdf.setDrawColor(0);
    pdf.setFillColor(255, 255, 255);
    pdf.roundedRect(10, 74, 190, 84, 1, 1, "FD");
    pdf.setDrawColor(0);
    pdf.setFillColor(190, 190, 190)
    pdf.setDrawColor(190, 190, 190)
    pdf.roundedRect(15, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(61, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(107, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(153, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(15, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(61, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(107, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(153, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(15, 132, 90, 20, 2, 2, "FD");
    pdf.roundedRect(107, 132, 90, 20, 2, 2, "FD");
    //Titulos
    pdf.setFontSize(11);
    pdf.setFont("times", "bold")
    pdf.text("SIMULAÇÃO DE CONSÓRCIO", 105, 83, { align: 'center' });
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.text("Grupo", 37, 95, { align: 'center' });
    pdf.text("Taxa administração", 83, 95, { align: 'center' });
    pdf.text("Adesão", 128, 95, { align: 'center' });
    pdf.text("Valor Crédito", 175, 95, { align: 'center' });

    pdf.text("Redução", 37, 117, { align: 'center' });
    pdf.text("Média Lance", 83, 117, { align: 'center' });
    pdf.text("Prazo", 128, 117, { align: 'center' });
    pdf.text("Próxima Assembleia", 175, 117, { align: 'center' });

    pdf.text("Parcela", 60, 139, { align: 'center' });
    pdf.text("Parcela com seguro", 152, 139, { align: 'center' });

    pdf.setFontSize(14);
    pdf.setFont("times", "normal")
    pdf.text(((dadosVenda.grupo==="69"?"EM FORMAÇÃO":dadosVenda.grupo) || 0).toString(), 37, 103, { align: 'center' });
    pdf.text(`${dadosVenda.taxaAdministracao}%`, 83, 103, { align: 'center' });
    pdf.text(`${dadosVenda.adesao}%`, 128, 103, { align: 'center' });
    pdf.text((dadosVenda.valorCreditoF || ''), 175, 103, { align: 'center' });

    pdf.text((dadosVenda.reducao || 0).toString(), 37, 125, { align: 'center' });
    pdf.text(`${dadosVenda.mediaLance}%`, 83, 125, { align: 'center' });
    pdf.text((dadosVenda.prazo || 0).toString(), 128, 125, { align: 'center' });
    pdf.text(dadosVenda.primeiraAssF, 175, 125, { align: 'center' });

    pdf.text((dadosVenda.valorParcelaFormatado || ""), 60, 147, { align: 'center' });
    pdf.text((dadosVenda.valorParcelaComSeguroFormatado || ""), 152, 147, { align: 'center' });

    //simulacao lance
    if (excluirLance) {
      pdf.setDrawColor(0);
      pdf.setFillColor(255, 255, 255);
      pdf.roundedRect(10, 160, 190, 84, 1, 1, "FD");
      pdf.setFontSize(11);
      pdf.setFont("times", "bold")
      pdf.text("SIMULAÇÃO DO LANCE", 105, 169, { align: 'center' });
      pdf.setDrawColor(0);
      pdf.setFillColor(190, 190, 190)
      pdf.setDrawColor(190, 190, 190)
      pdf.roundedRect(15, 172, 90, 20, 2, 2, "FD");
      pdf.roundedRect(107, 172, 90, 20, 2, 2, "FD");
      pdf.roundedRect(15, 194, 90, 20, 2, 2, "FD");
      pdf.roundedRect(107, 194, 90, 20, 2, 2, "FD");
      pdf.roundedRect(15, 216, 182, 20, 2, 2, "FD");

      //Titulos
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.text("Percentual de lance", 60, 179, { align: 'center' });
      pdf.text("Valor total do lance", 152, 179, { align: 'center' });
      pdf.text("Valor do lance embutido", 60, 201, { align: 'center' });
      pdf.text("Valor do lance com recursos próprios", 152, 201, { align: 'center' });
      pdf.text("Crédito liquido com lance embutido", 106, 223, { align: 'center' });

      pdf.setFontSize(14);
      pdf.setFont("times", "normal")
      pdf.text(`${dadosVenda.b2}%`, 60, 187, { align: 'center' });
      pdf.text(`${((dadosVenda.t_p2 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 152, 187, { align: 'center' });
      pdf.text(`${((dadosVenda.t_p3 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 60, 208, { align: 'center' });
      pdf.text((dadosVenda.b4 || ""), 152, 208, { align: 'center' });
      pdf.text((dadosVenda.b5 || ""), 106, 231, { align: 'center' });
    }

    //rodapé
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.text("Os valores expressos na simulação são aproximados e poderão ser reajustados sem aviso prévio", 10, 277, { align: 'left' });
    pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
    pdf.setDrawColor(0, 0, 0);
    pdf.line(10, 280, 200, 280);

    if (excluirComparativo) {
      //Pagina 2***********************
      pdf.addPage("a4");
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
      pdf.text(`USUÁRIO: ${nome?.toUpperCase()}`, 200, 8, { align: 'right' });
    // console.log(dados)
    contatos.map(function (item) {
      if (item.tipoContato !== "EMAIL" && continua3) {
        continua3 = false;
        return pdf.text(`TELEFONE ${item.valor || '(16) 3707-5500'}`, 200, 12.5, { align: 'right' });
      }
      if (item.tipoContato === "EMAIL" && continua4) {
        continua4 = false;
        return pdf.text(`E-MAIL: ${item.valor.toUpperCase()}`, 200, 17, { align: 'right' });
      }
    })
    if (continua3)
      pdf.text(`TELEFONE (16) 3707-5500`, 200, 13, { align: 'right' });

    pdf.text(`DATA : ${dataAtual}`, 200, 22, { align: 'right' });
      //pdf.table(23, 58, generateData(100), headers ,{ autoSize: true });
      pdf.line(10, 25, 200, 25); // horizontal line
      pdf.setFontSize(15);
      pdf.setFont("arial", "bold")
      pdf.text("Comparativo financiamento X Consórcio Groscon", 105, 33, { align: 'center' });
      pdf.line(10, 37, 200, 37);

      pdf.setDrawColor(0);
      pdf.setFillColor(255, 255, 255);
      pdf.roundedRect(10, 43, 190, 138, 1, 1, "FD");//14
      pdf.setDrawColor(0);
      pdf.setFillColor(190, 190, 190)
      pdf.setDrawColor(190, 190, 190)
      pdf.roundedRect(15, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(61, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(107, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(153, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(15, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(61, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(107, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(153, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(15, 124, 90, 20, 2, 2, "FD");
      pdf.roundedRect(107, 124, 90, 20, 2, 2, "FD");

      pdf.setFontSize(11);
      pdf.setFont("times", "bold")
      pdf.text("CONSÓRCIO GROSCON", 105, 50, { align: 'center' });
      pdf.text("FINANCIAMENTO", 105, 85, { align: 'center' });
      pdf.text("ECONOMIA COM O CONSÓRCIO GROSCON", 105, 120, { align: 'center' });
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.text("Valor do bem", 37, 61, { align: 'center' });
      pdf.text("Prazo", 83, 61, { align: 'center' });
      pdf.text("Taxa de administração mensal", 129, 61, { align: 'center' });
      pdf.text("Valor da parcela mensal", 175, 61, { align: 'center' });

      pdf.text("Valor do bem", 37, 96, { align: 'center' });
      pdf.text("Prazo", 83, 96, { align: 'center' });
      pdf.text("Taxa Mensal", 128, 96, { align: 'center' });
      pdf.text("Valor da Parcela Mensal", 175, 96, { align: 'center' });

      pdf.text("Mensal", 60, 131, { align: 'center' });
      pdf.text("Total", 152, 131, { align: 'center' });

      pdf.setFontSize(14);
      pdf.setFont("times", "normal")
      pdf.text((dadosVenda.valorCreditoF || ''), 37, 68, { align: 'center' });
      pdf.text((dadosVenda.prazo || 0).toString(), 83, 68, { align: 'center' });
      pdf.text(`${dadosVenda.nomePai?.replace('.', ',')}%`, 128, 68, { align: 'center' });
      pdf.text((dadosVenda.valorParcelaFormatado || ''), 175, 68, { align: 'center' });

      pdf.text((dadosVenda.valorCreditoF || ''), 37, 103, { align: 'center' });
      pdf.text((dadosVenda.prazo || 0).toString(), 83, 103, { align: 'center' });
      pdf.text((dadosVenda.descTipo === 'IMÓVEL' ? '0,80%' : '1,50%'), 128, 103, { align: 'center' });
      pdf.text((dadosVenda.cidade || ""), 175, 103, { align: 'center' });

      pdf.text((dadosVenda.uf || ""), 60, 138, { align: 'center' });
      pdf.text((dadosVenda.apto || ""), 152, 138, { align: 'center' });


      pdf.setLineWidth(2);
      pdf.setDrawColor(180, 180, 180);
      pdf.setFillColor(255, 255, 255);
      pdf.circle(106, 160, 9, "FD");

      pdf.setFontSize(13);
      pdf.setFont("times", "normal")
      pdf.text(`${(dadosVenda.codBanco?.toString())?.replace('.', ',')}%`, 106, 161, { align: 'center' });

      pdf.setFontSize(10);
      pdf.setFont("times", "normal")
      pdf.text("Economia(%)", 106, 175, { align: 'center' });
      pdf.setLineWidth(0);
      //pdf.table(10, 180, generateData(10), headers ,{ autoSize: false });

      //rodapé
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.text("Os valores expressos na simulação são aproximados e poderão ser reajustados sem aviso prévio", 10, 277, { align: 'left' });
      pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 280, 200, 280);

    }

    //pdf.autoPrint();
    /*pdf.html(string,{
        async callback(doc) {
          // save the document as a PDF with name of Memes
          //doc.autoPrint();
          //doc.save("Memes");
        }
      })*/
    //pdf.fromHTML(string);
    //pdf.save("pdf");
    // pdf.autoPrint();
    if (tipo === "imprimir") {
      window.open(pdf.output('bloburl'));
    } else {
      pdf.save('Simulacao')
    }

  };


  const print2 = () => {

    console.log(dadosVenda)

  }

  const dataFormatada = (data: string) => {
    if (data === 'erro') {
      return "Erro sistema"
    } else {
      let dataF = data.slice(8, 10) + '/' + data.slice(5, 7) + '/' + data.slice(0, 4)
      return dataF
    }

  }






  return (
    <>
      <Header />

      <Container >


        <Tabelas>

          <Typography>
            <div className='title'><h2 onClick={print2}>Simulação {print2}</h2></div>
            {dadosVenda.codB === 0 ? <>
              <div className="tabela">
                <Form className="form-border" onSubmit={onSubmit}>
                  {/*<Row md={3}>
                    <Col xs={3}>
                      <Row>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginLeft: 8, color: "#ffffff" }}>.</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="tipoPessoaRadio"
                          onChange={handleChangeCheckPessoa}
                          value={dadosVenda.pessoa}
                        >
                          <FormControlLabel value="fisica" control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label="Fisíca" />
                          <FormControlLabel value="juridica" control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label="Jurídica" />
                        </RadioGroup>
                      </Row>

                    </Col>
                    <Col xs={5}></Col>
                    <Col>
                    </Col>
                  </Row>*/}




                  <Row>
                    <Row style={{ marginTop: "15px" }}>
                      <Col sm={3} xs={6} className="acima">
                        <div className="inputNovo">
                          <Form.Group className="select">
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Tipo
                            </InputLabel>
                            <NativeSelect
                              style={{ width: "100%" }}
                              className="inputNovo"

                              onChange={handleChangeTipo}
                              value={filtroTipo > 0 ? filtroTipo : '99'}
                              inputProps={{
                                name: 'nivel',
                                id: 'uncontrolled-native',
                              }}
                            >
                              <option value={0}>TODOS</option>
                              {bensPorVendedor?.map(function (item) {
                                console.log(item)
                                let teste = item.tiposBem?.sort(function (a, b) {
                                  if (a.descricao > b.descricao) {
                                    return 1
                                  }
                                  if (a.descricao < b.descricao) {
                                    return -1
                                  }
                                  return 0
                                })
                                return (
                                  teste?.map(function (item2) {
                                    return (
                                      <>
                                        <option id={item2.codigoGrupoBem.toString()} value={item2.codigoGrupoBem}>
                                          {item2.descricao}
                                        </option>
                                      </>
                                    )
                                  })
                                )
                              })
                              }
                            </NativeSelect>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col sm={2} xs={6} className="acima">
                        <div className="inputNovo">
                          <Form.Group className="select">
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Grupo
                            </InputLabel>
                            <NativeSelect
                              style={{ width: "100%" }}
                              className="inputNovo"
                              defaultValue={30}
                              onChange={handleChangeGrupo}
                              value={dadosVenda.grupo}
                              inputProps={{
                                name: 'nivel',
                                id: 'uncontrolled-native',
                              }}
                            >
                              <option value={0}>TODOS</option>
                              {grupos?.map(item => {
                                let mostraItem = item.toString()
                                return (

                                  <option id={item.toString()} value={item}>
                                    {mostraItem==="69"?"EM FORMAÇÃO":mostraItem}
                                  </option>

                                )
                              }
                              )
                              }
                            </NativeSelect>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col sm={7} xs={12} className="acima1">


                        <Form.Group as={Col} controlId="formGridBem" style={{ marginTop: -14 }}>
                          <div className="labelBem"><InputLabel variant="standard" htmlFor="uncontrolled-native" >
                            Valor do bem
                          </InputLabel></div>
                          <Box sx={{ marginTop: 1, marginRight: 4, marginLeft: 4 }}>
                            <Slider
                              getAriaLabel={() => 'Valor bem'}
                              value={value}
                              min={menorValorBem}
                              max={maiorValorBem}
                              sx={{ color: 'var(--blue) ' }}
                              onChange={handleChange}
                              valueLabelDisplay="on"
                              valueLabelFormat={value => <div style={{ fontSize: 12 }}>{value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>}
                              getAriaValueText={valuetext}
                            />
                          </Box>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>

                  <Row style={{ marginTop: 20 }}>
                    <Row>
                    <Col sm={2} xs={6}>
                      <div className="inputNovo">
                        <FormControl className="inputNovo3" variant="standard" style={{ marginTop: 7 }} >
                          <TextField
                            className='inputNovo'
                            id="standard-required"
                            label="Cód. Bem"
                            variant="standard"
                            name='nomePrincipal'
                            value={filtroBem}
                            onChange={handleChangeBemInput}
                          />
                        </FormControl>
                      </div>
                    </Col>
                    <Col sm={3} xs={6}>
                      <div className="inputNovo">
                        <Form.Group className="select">
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Bem
                          </InputLabel>
                          <NativeSelect
                            style={{ width: "100%" }}
                            className="inputNovo"
                            defaultValue={30}
                            onChange={handleChangeBem}
                            value={filtroBem}
                            inputProps={{
                              name: 'nivel',
                              id: 'uncontrolled-native',
                            }}
                          >
                            <option value={"0"}>TODOS</option>
                            {bensPorVendedor1?.map(function (item2) {
                              return (
                                <>
                                  <option id={item2.idBem.toString()} value={item2.idBem}>
                                    {item2.idBem} {item2.descricaoBem}
                                  </option>
                                </>
                              )
                            })
                            }
                          </NativeSelect>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col sm={7} xs={12} className="acima1">


                      <Form.Group as={Col} controlId="formGridBem" style={{ marginTop: -14 }}>
                        <div className="labelBem"><InputLabel variant="standard" htmlFor="uncontrolled-native" >
                          Quantidade de parcelas
                        </InputLabel></div>
                        <Box sx={{ marginTop: 1, marginRight: 4, marginLeft: 4 }}>
                          <Slider
                            getAriaLabel={() => 'Valor bem'}
                            value={value1}
                            min={menorPrazoBem}
                            max={maiorPrazoBem}
                            sx={{ color: 'var(--blue) ' }}
                            onChange={handleChange1}
                            valueLabelDisplay="on"
                            valueLabelFormat={value => <div style={{ fontSize: 12 }}>{value}</div>}
                            getAriaValueText={valuetext}
                          />
                        </Box>
                      </Form.Group>
                    </Col>
                    </Row>
                  </Row>



                  <div className="botaoPesquisar" style={{ marginBottom: "20px" }}>
                    <Button variant="primary" className="btnPesquisar" onClick={handleSearchTodos} style={{ marginLeft: "15" }}>
                      <Typography variant="button" gutterBottom> Pesquisar</Typography>
                    </Button>
                    <Button variant="primary" className="btnPesquisar" onClick={limparTodos} style={{ marginRight: "15" }}>
                      <Typography variant="button" gutterBottom>LIMPAR</Typography>
                    </Button>



                  </div>


                </Form>
              </div>
              <div className="tabela3">
                {dadosVenda.visualizaBens === true ? <>
                  {!dadosVenda.bloqueado ? <>

                    {dadosVenda.sequenciaAgrupamento === 0 ?
                      <div style={{
                        maxWidth: '1100px',
                        fontSize: '13px',
                        paddingLeft: '24px',
                        paddingRight: '24px'
                      }}>
                        <Tabelas1>
                          <Table responsive className="bordered">
                            <thead>
                              <tr>
                                <th className="ac tamanhoAC">Grupo</th>
                                <th className="ac">Bem</th>
                                <th className="ac">Valor Bem</th>
                                <th className="ac">Taxa Adm.</th>
                                <th className="ac">Próx. Assembleia</th>
                                <th className="ac">Prazo</th>
                                <th className="ac">Parcela</th>
                                <th className="ac">Parcela c/ Seg.</th>
                                <th className="ac">Média Lance</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              {_DATA.currentData().map(item => {
                                let grupo = (item.codigoGrupo).toString()


                                return (
                                  <>
                                    <tr className="ac tamanhoAC">
                                      <td className="ac tamanhoAC">{grupo==="69"?"EM FORMAÇÃO":grupo}</td>
                                      <td className="ac"><Typography noWrap variant="body2">{item.idBem + " - "}{item.descricaoBem}</Typography></td>
                                      <td className="ac">{item.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                      <td className="ac">{item.taxa}%</td>
                                      <td className="ac">{formatData(item.dataAssembleia)}</td>
                                      <td className="ac">{item.prazo}</td>
                                      <td className="ac">{item.parcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                      <td className="ac">{item.parcelaSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                      <td className="ac tamanhoAC"><CircularProgressWithLabel size={30} variant="determinate" value={parseFloat(item.mediaLance.toFixed(2))} /></td>
                                      <td className="ac"><Button onClick={() => selecionaBem(item)} style={{ backgroundColor: "white", border: "none" }}>
                                        <FaList style={{ backgroundColor: "white", color: "black" }} />
                                      </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}

                              <tr style={{ height: "60" }}>
                                <td colSpan={10} style={{ height: "60" }}>
                                  <Pagination className="paginacao" count={count} page={page} onChange={handleChangePagination} size="large" style={{ marginTop: "10" }} />
                                </td>
                              </tr>

                            </tbody>
                          </Table>
                        </Tabelas1>
                      </div> :
                      <>
                        <div className="bemSelecionado">
                          <Row>
                            <Col xs={11} className="tituloBem">Plano selecionado <FiEdit2 style={{ cursor: "pointer" }} onClick={() => setOpen(true)}></FiEdit2></Col>
                            <Col className="tituloBem direita"><div className="direita1"><FiX style={{ cursor: "pointer" }}></FiX></div></Col>
                          </Row>


                          <>

                            <Typography>

                              <Table responsive bordered className="fonteTI">

                                <tbody>
                                  <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                    <td className="ac n tamanhoAC">Grupo</td>
                                    <td className="ac n">Bem</td>
                                    <td className="ac n">Valor do bem</td>
                                    <td className="ac n">Taxa Adm.</td>
                                    <td className="ac n">Próx. Assembleia</td>
                                    <td className="ac n">Prazo</td>
                                    <td className="ac n">Adesão</td>
                                    <td className="ac n">Adesão</td>
                                    <td className="ac n">Parcela</td>
                                    <td className="ac n">Parcela c/ seguro</td>
                                    <td className="ac n">Média Lance</td>

                                  </tr>

                                  <tr >
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.grupo==="69"?"EM FORMAÇÃO":dadosVenda.grupo} </td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.codB + " - "} {dadosVenda.descricaoBem}</td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.valorCreditoF}</td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.taxaAdministracao + '%'}</td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.prazo}</td>
                                    <td className="ac tam tamanhoAC">{dadosVenda.adesao + "%"}</td>
                                    <td className="ac tam">{dadosVenda.valorParcelaComSeguro >= 0 ? (dadosVenda.valorParcelaComSeguro / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</td>
                                    <td className="ac"><CircularProgressWithLabel size={25} variant="determinate" value={dadosVenda.mediaLance >= 0 ? parseFloat(dadosVenda.mediaLance.toFixed(2)) : 0} /></td>
                                  </tr>

                                </tbody>
                              </Table>

                            </Typography>
                          </>

                        </div>


                      </>
                    }


                  </> : <></>}
                </> : <></>}</div>

            </> :
              <>
                {visualizar === false ? <>

                  <div className="fab">
                    <button className="main13" onClick={visualizarSimulacao}>
                      <MdVisibility className="teste56" />
                    </button>
                  </div>


                  <Tabelas1 style={{ padding: '0' }}>
                    <Table responsive hover bordered style={{ padding: '0', backgroundColor: "#f4f4f4e1" }}>

                      <tbody>
                        <tr>
                          <td><FiArrowLeft onClick={voltar} className='voltar1' /></td>
                          <td className="ac n tamanhoAC">Grupo</td>
                          <td className="ac n">Bem</td>
                          <td className="ac n">Valor do bem</td>
                          <td className="ac n">Taxa</td>
                          <td className="ac n">Próxima Assembleia</td>
                          <td className="ac n">Valor Parcela</td>
                          <td className="ac n">Prazo</td>
                          <td className="ac n">Média Lance</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.grupo==="69"?"EM FORMAÇÃO":dadosVenda.grupo} </td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.codB + " -"} {dadosVenda.descricaoBem}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.valorCreditoF}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.taxaAdministracao}%</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.primeiraAssF}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.valorParcelaFormatado}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.prazo}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}><CircularProgressWithLabel size={30} variant="determinate" value={parseFloat(dadosVenda.mediaLance.toFixed(2))} /></td>
                        </tr>

                      </tbody>
                    </Table>
                  </Tabelas1>


                  <div className="tabela4">

                    <Box
                      sx={{ flexGrow: 1, bgcolor: 'white', display: 'flex', marginLeft: '10px' }}
                      
                    >

                      <Tab.Container id="left-tabs-example" defaultActiveKey="adesao">
                        <Row style={{width: '100%'}}>
                          <Col sm={1} xs={12} className="menuLateral">
                            <Nav variant="pills" style={{marginLeft: '10px'}} className="direcionamentoBotao">
                              <Nav.Item>
                                <Nav.Link eventKey="adesao" color="red"><MdOutlineAssignmentTurnedIn size={30} /></Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="calendario"><MdDateRange size={30} color={valuel === 2 ? 'var(--blue)' : ''} /></Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="caracteristicas"><MdInfo size={30} color={valuel === 3 ? 'var(--blue)' : ''} /></Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="comparativo"><MdSwapHoriz size={30} color={valuel === 4 ? 'var(--blue)' : ''} /></Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="calculadora"><MdIso size={30} color={valuel === 5 ? 'var(--blue)' : ''} /></Nav.Link>
                              </Nav.Item>

                            </Nav>
                          </Col>
                          <Col sm={11} xs={12} style={{ paddingLeft: "20px", paddingBottom: "10px", maxWidth: "990px" }}>
                            <Tab.Content>
                              <Tab.Pane eventKey="adesao">
                                <Typography id="modal-modal-title" variant="h6" component="h5">
                                  <div style={{ color: "var(--blue)", marginTop: '10px' }}>
                                    <b>Redução</b>
                                  </div>
                                  <Row className="tipoPessoa">
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      className="tipoPessoaRadio"
                                      value={dadosVenda.reducao}
                                      onChange={handleChangeCheckReducao}
                                    >
                                      {reducaoModal.map(item => (
                                        <FormControlLabel value={item} control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label={item + '%'} />
                                      ))}
                                    </RadioGroup>
                                  </Row>
                                </Typography>

                                <Typography variant="h6" component="h5">
                                  <div style={{ marginTop: "10px", color: "var(--blue)" }}>
                                    <b>Adesão</b>
                                  </div>
                                </Typography>

                                {bemSelecionado.map(item => {
                                  console.log('item bem', bemSelecionado)
                                  if (item.reducao === valorReducao && item.parcelaInicial === 1 && dadosVenda.valorCredito !== undefined) {
                                    if (item.adesao === 0 && item.reducao === 0 && dadosVenda.sequenciaAgrupamento === 0) {
                                      selecionaBemFinal(item)
                                      setSelectedValue1(item.sequenciaAgrupamento.toString())
                                      let dadosPlanos = planosAgrupamento.filter(item2 => item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
                                      setPlanoAgrupamento(dadosPlanos)
                                    }

                                    return (
                                      <>
                                        <Tabelas1 style={{marginLeft: '2px'}}>
                                          <Table responsive bordered className="fonteTI" style={{ marginTop: "10px", paddingRight: '2px', width: "100%" }} >
                                            <tbody>
                                              <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                                <td className="ac3 n"></td>
                                                <td className="ac3 n">{window.screen.width < 600 ? '' : 'Valor '}Adesão</td>
                                                <td className="ac3 n">Parcela</td>
                                                <td className="ac3 n">Parc{window.screen.width < 600 ? '.' : 'ela'} com Seg{window.screen.width < 600 ? '.' : 'uro'}</td>
                                                <td></td>

                                              </tr>
                                              <tr style={{ fontSize: "18px" }}>
                                                <td className="ac3 redm"><div style={{ zIndex: "9999" }}>
                                                  <FormControlLabel label={item.adesao + "%"} control={<Radio size="small" name="escolhaBem" onChange={handleChangeOption1} checked={selectedValue1 === (item.sequenciaAgrupamento).toString()}
                                                    value={(item.sequenciaAgrupamento)}
                                                    sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} onClick={() => selecionaBemFinal(item)} />
                                                </div></td>
                                                <td className="ac3 tam">{item.adesao === 0 ? '-' : (item.adesao * dadosVenda.valorCredito / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="ac3 tam">{item.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="ac3 tam">{item.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="ac3 tam" onClick={() => mostrar(item.sequenciaAgrupamento)}>{mostrarModal === item.sequenciaAgrupamento && visible ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</td>

                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Tabelas1>
                                        {mostrarModal === item.sequenciaAgrupamento && visible ?
                                          <>
                                            <Tabelas1>
                                              <Table responsive bordered className="fonteTI">
                                                <thead style={{ backgroundColor: "#f4f4f4e1" }}>
                                                  <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                                    <th className="ac tamanhoAC">Parcela</th>
                                                    <th className="ac">Valor S/ Seguro</th>
                                                    <th className="ac">Valor C/ Seguro</th>

                                                  </tr>


                                                  {bemSelecionado.map(item2 => {
                                                    if (item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
                                                      return (
                                                        <>

                                                          <tr>
                                                            <td className="ac tamanhoAC">{item2.parcelaInicial} a {item2.parcelaFinal}</td>
                                                            <td className="ac">{item2.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                            <td className="ac">{item2.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>

                                                          </tr>


                                                        </>
                                                      )
                                                  })}
                                                </thead>
                                              </Table></Tabelas1></> : <></>

                                        }

                                      </>
                                    )
                                  }

                                })}


                              </Tab.Pane>
                              <Tab.Pane eventKey="calendario">
                                <Typography id="modal-modal-title" variant="h6" component="h4">
                                  <div style={{ color: "var(--blue)", marginBottom: '20px', marginTop: '10px' }}>
                                    <b>Calendário de Assembléia</b>
                                  </div>
                                  {dadosVenda.grupo==="69"?<>
                                  <Row>
                                      <Col sm={4} xs={12}>
                                        <ul>
                                          <li>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <div style={{
                                                borderRadius: '50%', border: '2px solid #9e9e9e', width: '12px', height: '12px'
                                              }}>



                                              </div>
                                              <div style={{
                                                left: '10px',
                                                height: '2px',
                                                width: 'calc(100% - 8px)',
                                                top: '5px',
                                                border: '1px solid #9e9e9e'
                                              }}>

                                              </div>
                                            </div>
                                          </li>
                                          <li style={{ marginLeft: '15px' }}>
                                            <div style={{ fontSize: '13px' }}>
                                            14/12/2023


                                            </div>
                                            <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                              <b><FiClock /> 17h30</b>
                                            </div>
                                          </li>
                                          <li style={{ marginLeft: '15px' }}>
                                            <div style={{ fontSize: '13px' }}>
                                            13/12/2023


                                              - Loteria Federal
                                            </div>
                                            <div style={{ fontSize: '13px' }}>
                                            11/12/2023

                                              - Vencimento
                                            </div>
                                            <div style={{ fontSize: '13px' }}>
                                              <FaMapMarkerAlt /> Franca/SP
                                            </div>

                                          </li>
                                        </ul>
                                      </Col>
                                    
                                  </Row>
                                  </>:<>
                                  <Row>
                                    {window.screen.width > 600 ? <>
                                      <Col sm={4} xs={12}>
                                        <ul>
                                          <li>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <div style={{
                                                borderRadius: '50%', border: '2px solid #9e9e9e', width: '12px', height: '12px'
                                              }}>



                                              </div>
                                              <div style={{
                                                left: '10px',
                                                height: '2px',
                                                width: 'calc(100% - 8px)',
                                                top: '5px',
                                                border: '1px solid #9e9e9e'
                                              }}>

                                              </div>
                                            </div>
                                          </li>
                                          <li style={{ marginLeft: '15px' }}>
                                            <div style={{ fontSize: '13px' }}>
                                              {assMes === 11 ?
                                                ass1['10'].dataAss : ''
                                              }
                                              {assMes === 10 ?
                                                ass1['09'].dataAss : ''
                                              }
                                              {assMes === 12 ?
                                                ass1['11'].dataAss : ''
                                              }
                                              {assMes === 1 ?
                                                ass1['12'].dataAss : ''
                                              }
                                              {assMes === 2 ?
                                                ass1['01'].dataAss : ''
                                              }
                                              {assMes === 3 ?
                                                ass1['02'].dataAss : ''
                                              }
                                              {assMes === 4 ?
                                                ass1['03'].dataAss : ''
                                              }
                                              {assMes === 5 ?
                                                ass1['04'].dataAss : ''
                                              }
                                              {assMes === 6 ?
                                                ass1['05'].dataAss : ''
                                              }
                                              {assMes === 7 ?
                                                ass1['06'].dataAss : ''
                                              }
                                              {assMes === 8 ?
                                                ass1['07'].dataAss : ''
                                              }
                                              {assMes === 9 ?
                                                ass1['08'].dataAss : ''
                                              }


                                            </div>
                                            <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                              <b><FiClock /> 17h30</b>
                                            </div>
                                          </li>
                                          <li style={{ marginLeft: '15px' }}>
                                            <div style={{ fontSize: '13px' }}>
                                              {assMes === 11 ?
                                                ass1['10'].dataLot : ''
                                              }
                                              {assMes === 10 ?
                                                ass1['09'].dataLot : ''
                                              }
                                              {assMes === 12 ?
                                                ass1['11'].dataLot : ''
                                              }
                                              {assMes === 1 ?
                                                ass1['12'].dataLot : ''
                                              }
                                              {assMes === 2 ?
                                                ass1['01'].dataLot : ''
                                              }
                                              {assMes === 3 ?
                                                ass1['02'].dataLot : ''
                                              }
                                              {assMes === 4 ?
                                                ass1['03'].dataLot : ''
                                              }
                                              {assMes === 5 ?
                                                ass1['04'].dataLot : ''
                                              }
                                              {assMes === 6 ?
                                                ass1['05'].dataLot : ''
                                              }
                                              {assMes === 7 ?
                                                ass1['06'].dataLot : ''
                                              }
                                              {assMes === 8 ?
                                                ass1['07'].dataLot : ''
                                              }
                                              {assMes === 9 ?
                                                ass1['08'].dataLot : ''
                                              }


                                              - Loteria Federal
                                            </div>
                                            <div style={{ fontSize: '13px' }}>
                                              {assMes === 11 ?
                                                ass1['10'].dataVen : ''
                                              }
                                              {assMes === 10 ?
                                                ass1['09'].dataVen : ''
                                              }
                                              {assMes === 12 ?
                                                ass1['11'].dataVen : ''
                                              }
                                              {assMes === 1 ?
                                                ass1['12'].dataVen : ''
                                              }
                                              {assMes === 2 ?
                                                ass1['01'].dataVen : ''
                                              }
                                              {assMes === 3 ?
                                                ass1['02'].dataVen : ''
                                              }
                                              {assMes === 4 ?
                                                ass1['03'].dataVen : ''
                                              }
                                              {assMes === 5 ?
                                                ass1['04'].dataVen : ''
                                              }
                                              {assMes === 6 ?
                                                ass1['05'].dataVen : ''
                                              }
                                              {assMes === 7 ?
                                                ass1['06'].dataVen : ''
                                              }
                                              {assMes === 8 ?
                                                ass1['07'].dataVen : ''
                                              }
                                              {assMes === 9 ?
                                                ass1['08'].dataVen : ''
                                              }

                                              - Vencimento
                                            </div>
                                            <div style={{ fontSize: '13px' }}>
                                              <FaMapMarkerAlt /> Franca/SP
                                            </div>

                                          </li>
                                        </ul>
                                      </Col></> : <></>}

                                    <Col sm={4} xs={12}>
                                      <ul style={{ color: "var(--blue)" }}>
                                        <li>
                                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{
                                              borderRadius: '50%', border: '2px solid var(--blue)', width: '12px', height: '12px', backgroundColor: 'var(--blue)'
                                            }}>



                                            </div>
                                            <div style={{
                                              height: '2px',
                                              width: 'calc(100% - 8px)',
                                              top: '5px',
                                              border: '1px solid var(--blue)'
                                            }}>

                                            </div>
                                          </div>
                                        </li>
                                        <li style={{ marginLeft: '15px' }}>
                                          <div style={{ fontSize: '13px' }}>
                                            {assMes === 11 ?
                                              ass1['11'].dataAss : ''
                                            }
                                            {assMes === 10 ?
                                              ass1['10'].dataAss : ''
                                            }
                                            {assMes === 12 ?
                                              ass1['12'].dataAss : ''
                                            }
                                            {assMes === 1 ?
                                              ass1['01'].dataAss : ''
                                            }
                                            {assMes === 2 ?
                                              ass1['02'].dataAss : ''
                                            }
                                            {assMes === 3 ?
                                              ass1['03'].dataAss : ''
                                            }
                                            {assMes === 4 ?
                                              ass1['04'].dataAss : ''
                                            }
                                            {assMes === 5 ?
                                              ass1['05'].dataAss : ''
                                            }
                                            {assMes === 6 ?
                                              ass1['06'].dataAss : ''
                                            }
                                            {assMes === 7 ?
                                              ass1['07'].dataAss : ''
                                            }
                                            {assMes === 8 ?
                                              ass1['08'].dataAss : ''
                                            }
                                            {assMes === 9 ?
                                              ass1['09'].dataAss : ''
                                            }


                                          </div>
                                          <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                            <b><FiClock /> 17h30</b>
                                          </div>
                                        </li>
                                        <li style={{ marginLeft: '15px' }}>
                                          <div style={{ fontSize: '13px' }}>
                                            {assMes === 11 ?
                                              ass1['11'].dataLot : ''
                                            }
                                            {assMes === 10 ?
                                              ass1['10'].dataLot : ''
                                            }
                                            {assMes === 12 ?
                                              ass1['12'].dataLot : ''
                                            }
                                            {assMes === 1 ?
                                              ass1['01'].dataLot : ''
                                            }
                                            {assMes === 2 ?
                                              ass1['02'].dataLot : ''
                                            }
                                            {assMes === 3 ?
                                              ass1['03'].dataLot : ''
                                            }
                                            {assMes === 4 ?
                                              ass1['04'].dataLot : ''
                                            }
                                            {assMes === 5 ?
                                              ass1['05'].dataLot : ''
                                            }
                                            {assMes === 6 ?
                                              ass1['06'].dataLot : ''
                                            }
                                            {assMes === 7 ?
                                              ass1['07'].dataLot : ''
                                            }
                                            {assMes === 8 ?
                                              ass1['08'].dataLot : ''
                                            }
                                            {assMes === 9 ?
                                              ass1['09'].dataLot : ''
                                            }

                                            - Loteria Federal
                                          </div>
                                          <div style={{ fontSize: '13px' }}>
                                            {assMes === 11 ?
                                              ass1['11'].dataVen : ''
                                            }
                                            {assMes === 10 ?
                                              ass1['10'].dataVen : ''
                                            }
                                            {assMes === 12 ?
                                              ass1['12'].dataVen : ''
                                            }
                                            {assMes === 1 ?
                                              ass1['01'].dataVen : ''
                                            }
                                            {assMes === 2 ?
                                              ass1['02'].dataVen : ''
                                            }
                                            {assMes === 3 ?
                                              ass1['03'].dataVen : ''
                                            }
                                            {assMes === 4 ?
                                              ass1['04'].dataVen : ''
                                            }
                                            {assMes === 5 ?
                                              ass1['05'].dataVen : ''
                                            }
                                            {assMes === 6 ?
                                              ass1['06'].dataVen : ''
                                            }
                                            {assMes === 7 ?
                                              ass1['07'].dataVen : ''
                                            }
                                            {assMes === 8 ?
                                              ass1['08'].dataVen : ''
                                            }
                                            {assMes === 9 ?
                                              ass1['09'].dataVen : ''
                                            }
                                            - Vencimento
                                          </div>
                                          <div style={{ fontSize: '13px' }}>
                                            <FaMapMarkerAlt /> Franca/SP
                                          </div>

                                        </li>
                                      </ul>
                                    </Col>

                                    <Col sm={4} xs={12}>
                                      <ul>
                                        <li>
                                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{
                                              borderRadius: '50%', border: '2px solid #9e9e9e', width: '12px', height: '12px'
                                            }}>



                                            </div>
                                            <div style={{
                                              left: '10px',
                                              height: '2px',
                                              width: 'calc(100% - 8px)',
                                              top: '5px',
                                              border: '1px solid #9e9e9e'
                                            }}>

                                            </div>
                                          </div>
                                        </li>
                                        <li style={{ marginLeft: '15px' }}>
                                          <div style={{ fontSize: '13px' }}>
                                            {assMes === 11 ?
                                              ass1['12'].dataAss : ''
                                            }
                                            {assMes === 10 ?
                                              ass1['11'].dataAss : ''
                                            }
                                            {assMes === 12 ?
                                              ass1['01'].dataAss : ''
                                            }
                                            {assMes === 1 ?
                                              ass1['02'].dataAss : ''
                                            }
                                            {assMes === 2 ?
                                              ass1['03'].dataAss : ''
                                            }
                                            {assMes === 3 ?
                                              ass1['04'].dataAss : ''
                                            }
                                            {assMes === 4 ?
                                              ass1['05'].dataAss : ''
                                            }
                                            {assMes === 5 ?
                                              ass1['06'].dataAss : ''
                                            }
                                            {assMes === 6 ?
                                              ass1['07'].dataAss : ''
                                            }
                                            {assMes === 7 ?
                                              ass1['08'].dataAss : ''
                                            }
                                            {assMes === 8 ?
                                              ass1['09'].dataAss : ''
                                            }
                                            {assMes === 9 ?
                                              ass1['10'].dataAss : ''
                                            }



                                          </div>
                                          <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                            <b><FiClock /> 17h30</b>
                                          </div>
                                        </li>
                                        <li style={{ marginLeft: '15px' }}>
                                          <div style={{ fontSize: '13px' }}>
                                            {assMes === 11 ?
                                              ass1['12'].dataLot : ''
                                            }
                                            {assMes === 10 ?
                                              ass1['11'].dataLot : ''
                                            }
                                            {assMes === 12 ?
                                              ass1['01'].dataLot : ''
                                            }
                                            {assMes === 1 ?
                                              ass1['02'].dataLot : ''
                                            }
                                            {assMes === 2 ?
                                              ass1['03'].dataLot : ''
                                            }
                                            {assMes === 3 ?
                                              ass1['04'].dataLot : ''
                                            }
                                            {assMes === 4 ?
                                              ass1['05'].dataLot : ''
                                            }
                                            {assMes === 5 ?
                                              ass1['06'].dataLot : ''
                                            }
                                            {assMes === 6 ?
                                              ass1['07'].dataLot : ''
                                            }
                                            {assMes === 7 ?
                                              ass1['08'].dataLot : ''
                                            }
                                            {assMes === 8 ?
                                              ass1['09'].dataLot : ''
                                            }
                                            {assMes === 9 ?
                                              ass1['10'].dataLot : ''
                                            }
                                            - Loteria Federal
                                          </div>
                                          <div style={{ fontSize: '13px' }}>
                                            {assMes === 11 ?
                                              ass1['12'].dataVen : ''
                                            }
                                            {assMes === 10 ?
                                              ass1['11'].dataVen : ''
                                            }
                                            {assMes === 12 ?
                                              ass1['01'].dataVen : ''
                                            }
                                            {assMes === 1 ?
                                              ass1['02'].dataVen : ''
                                            }
                                            {assMes === 2 ?
                                              ass1['03'].dataVen : ''
                                            }
                                            {assMes === 3 ?
                                              ass1['04'].dataVen : ''
                                            }
                                            {assMes === 4 ?
                                              ass1['05'].dataVen : ''
                                            }
                                            {assMes === 5 ?
                                              ass1['06'].dataVen : ''
                                            }
                                            {assMes === 6 ?
                                              ass1['07'].dataVen : ''
                                            }
                                            {assMes === 7 ?
                                              ass1['08'].dataVen : ''
                                            }
                                            {assMes === 8 ?
                                              ass1['09'].dataVen : ''
                                            }
                                            {assMes === 9 ?
                                              ass1['10'].dataVen : ''
                                            }
                                            - Vencimento
                                          </div>
                                          <div style={{ fontSize: '13px' }}>
                                            <FaMapMarkerAlt /> Franca/SP
                                          </div>

                                        </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                  </>}

                                </Typography>

                              </Tab.Pane>
                              <Tab.Pane eventKey="caracteristicas">
                                <Typography id="modal-modal-title" variant="h6" component="h4">
                                  <div style={{ color: "var(--blue)", marginBottom: '10px', marginTop: '10px' }}>
                                    <b>Características</b>
                                  </div>
                                  <Row style={{ color: "var(--blue)", marginTop: 20 }}>
                                    <h6 style={{ margin: 0 }}>
                                      Grupo
                                    </h6>
                                    <p className="text-carac" style={{ fontSize: '13px', color: '#666666' }}>
                                      Grupo constituído de créditos de valores diferenciados
                                    </p>
                                  </Row>
                                  <Col className="title-blue no-margin">
                                    <Row>
                                      <Col sm={4} xs={6} style={{ marginBottom: '10px' }}>
                                        <p className="title-carac">Grupo</p>
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <FaListOl />
                                          <p className="text-carac">{dadosVenda.grupo==="69"?"EM FORMAÇÃO":dadosVenda.grupo}</p>
                                        </div>
                                      </Col>
                                      <Col sm={4} xs={6}>
                                        <p className="title-carac">Espécie</p>
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          {dadosVenda.grupo === "55" ? <MdLocalShipping /> :
                                            dadosVenda.grupo === "56" ? <FaHome /> : <MdDirectionsCar />}
                                          <p className="text-carac">{dadosVenda.grupo === "55" ? 'CAMINHÕES' :
                                            dadosVenda.grupo === "56" ? 'IMÓVEIS' : 'AUTOMÓVEIS'}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col sm={4} xs={12}>
                                        <p className="title-carac">Última atualização da Tabela</p>
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <FiClock />
                                          <p className="text-carac">{dataFormatada(bemSelecionado[0].dataReajuste || 'erro')}</p>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col sm={4} xs={12} style={{ marginBottom: '10px' }}>
                                        <p className="title-carac">Prazo de duração</p>
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <MdOutlineTimer />
                                          <p className="text-carac">{dadosVenda.planoBasico} Meses</p>
                                        </div>
                                      </Col>
                                      <Col sm={4} xs={12}>
                                        <p className="title-carac">Máximo de consorciados ativos</p>
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <MdOutlineShowChart />
                                          <p className="text-carac">{dadosVenda.numMax}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col sm={4} xs={12}>
                                        <p className="title-carac">Índice de correção</p>
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <BsListCheck />
                                          <p className="text-carac">{dadosVenda.indiceCorrecao}</p>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col sm={4} xs={12}>
                                        {renderCaracteristica('Sede do grupo', 'Franca/SP', <FaMapMarkerAlt />)}
                                      </Col>
                                      <Col sm={4} xs={12}>
                                        {renderCaracteristica('Local da assembleia', 'Franca/SP', <FaMapMarkerAlt />)}
                                      </Col>


                                      <Col />
                                    </Row>
                                  </Col>

                                  <Row style={{ color: "var(--blue)" }} className="acima2">
                                    <h6 style={{ margin: 0 }}>
                                      Dados do plano
                                    </h6>
                                  </Row>
                                  <Row style={{ marginTop: "20px" }}>
                                    <Col sm={4} xs={12} >
                                      <div style={{ color: 'var(--blue)', fontSize: '16px' }}>
                                        Taxa administrativa mensal
                                      </div>
                                      <div style={{ fontSize: '13px' }}>
                                        <ul>
                                          {planoAgrupamento?.map(function (item) {
                                            return (
                                              <li>
                                                {item.parcelaInicial.toLocaleString('en-US', { minimumIntegerDigits: 3 })} - {item.parcelaFinal.toLocaleString('en-US', { minimumIntegerDigits: 3 })} = {item.percentualTaxaAdministracao.toFixed(4).replace('.', ',')}%
                                              </li>
                                            )
                                          })}

                                        </ul>

                                      </div>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                      <div style={{ color: 'var(--blue)', fontSize: '16px' }}>
                                        Taxa administrativa total
                                      </div>
                                      <div style={{ fontSize: '13px' }}>
                                        <ul>
                                          <li>
                                            {dadosVenda.taxaAdministracao}%
                                          </li>
                                        </ul>

                                      </div>
                                    </Col>
                                    <Col>
                                      <div style={{ color: 'var(--blue)', fontSize: '16px' }}>
                                        Adesão/Administrativa antecipada
                                      </div>
                                      <div style={{ fontSize: '13px' }}>
                                        <ul>
                                          <li>
                                            {dadosVenda.adesao}%
                                          </li>
                                        </ul>

                                      </div>
                                    </Col>

                                  </Row>

                                  <Row style={{ marginTop: "5px" }}>
                                    <Col sm={4} xs={12}>
                                      <div style={{ color: 'var(--blue)', fontSize: '16px' }}>
                                        Fundo comum mensal
                                      </div>
                                      <div style={{ fontSize: '13px' }}>
                                        <ul>
                                          {planoAgrupamento?.map(function (item) {
                                            return (
                                              <li>
                                                {item.parcelaInicial.toLocaleString('en-US', { minimumIntegerDigits: 3 })} - {item.parcelaFinal.toLocaleString('en-US', { minimumIntegerDigits: 3 })} = {item.percentualNormal.toFixed(4).replace('.', ',')}%
                                              </li>
                                            )
                                          })}

                                        </ul>

                                      </div>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                      <div style={{ color: 'var(--blue)', fontSize: '16px' }}>
                                        Fundo comum total
                                      </div>
                                      <div style={{ fontSize: '13px' }}>
                                        <ul>
                                          <li>
                                            100%
                                          </li>
                                        </ul>

                                      </div>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                      <div style={{ color: 'var(--blue)', fontSize: '16px' }}>
                                        Seguro de Vida
                                      </div>
                                      <div style={{ fontSize: '13px' }}>
                                        <ul>
                                          <li>
                                            0,0516%
                                          </li>
                                        </ul>

                                      </div>
                                    </Col>

                                  </Row>
                                </Typography>
                              </Tab.Pane>
                              <Tab.Pane eventKey="comparativo">
                                <Row>
                                  <Col>
                                    <div style={{ color: "var(--blue)", fontSize: '20px', marginTop: '10px' }}>
                                      <Typography variant="h6"><b>Comparativo {window.screen.width > 600 ? "" : <br />} Groscon x Financiamento</b></Typography>

                                    </div>
                                  </Col>
                                </Row>

                                <div style={{ color: "var(--blue)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <b>Consórcio Groscon </b>
                                  <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                                    {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                                      dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                                  </div>

                                </div>
                                <Row>
                                  <Col sm={3} xs={12}>
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                                    </div>

                                  </Col>
                                  <Col sm={3} xs={12} className="acima">
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                                    </div>
                                  </Col>
                                  <Col sm={3} xs={12}>
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa de administração mensal</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.nomePai?.replace('.', ',')}% </div>
                                    </div>
                                  </Col>
                                  <Col sm={3} xs={12} className="acima">
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaFormatado} </div>
                                    </div>
                                  </Col>

                                </Row>

                                <div style={{ color: "var(--blue)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <b>Financiamento</b>

                                  <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                                    {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                                      dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                                  </div>
                                  <div style={{ maxWidth: `${dadosVenda.naturalCidade}`, overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ fontSize: '20px' }}>
                                      {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                                        dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                                    </div>
                                  </div>
                                </div>
                                <Row>
                                  <Col sm={3} xs={12}>
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                                    </div>

                                  </Col>
                                  <Col sm={3} xs={12} className="acima">
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                                    </div>
                                  </Col>
                                  <Col sm={3} xs={12} className="acima">
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa mensal</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.descTipo === 'IMÓVEL' ? '0,80%' : '1,50%'}</div>
                                    </div>
                                  </Col>
                                  <Col sm={3} xs={12} className="acima">
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.cidade} </div>
                                    </div>
                                  </Col>

                                </Row>

                                <div style={{ color: "var(--blue)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                                  <b>Economia com o Consórcio Groscon</b>
                                </div>
                                <Row>
                                  <Col sm={6} xs={12}>
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Mensal</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.uf} </div>
                                    </div>

                                  </Col>
                                  <Col sm={6} xs={12} className="acima">
                                    <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: '#63758E' }}>Total</div>
                                      <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.apto} </div>
                                    </div>
                                  </Col>

                                </Row>
                                <Row>
                                  <Col>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                                      <div style={{ fontSize: '120px', color: '#63758E' }}><CircularProgressWithLabel1 style={{ borderRadius: '50%' }} size={120} variant="determinate" value={dadosVenda.codBanco || 0} /> </div>
                                      <div style={{ fontSize: '12px', color: '#63758E', marginTop: '-33px' }}>Economia(%)</div>
                                    </div>
                                  </Col>

                                </Row>
                              </Tab.Pane>
                              <Tab.Pane eventKey="calculadora">
                                <div style={{ color: "var(--blue)", fontSize: '20px', marginTop: '10px', marginLeft: '15px' }}>
                                  <b>Calculadora de lance</b>
                                </div>


                                <Row style={{ marginTop: "10px" }}>
                                  <Col sm={6} xs={6}>
                                    <div onClick={() => alteraTipoLance('livre')} style={dadosVenda.b1 === 'livre' ? { cursor: 'pointer', backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' } : { cursor: 'pointer', backgroundColor: '#fafafa', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                                      <div style={dadosVenda.b1 === 'livre' ? { fontSize: '19px', color: 'var(--blue)' } : { fontSize: '19px', color: '#63758E' }}>Lance Livre</div>
                                      <div style={dadosVenda.b1 === 'livre' ? { fontSize: '12px', color: 'var(--blue)' } : { fontSize: '12px', color: '#63758E' }}>{(dadosVenda.grupo === ("56")) ? '(Grupo sem lance máximo)' :(dadosVenda.grupo === ("55")) ? '(Grupo sem lance máximo)': `(Lance Máximo ${dadosVenda.numero}%)`}</div>
                                    </div>

                                  </Col>
                                  <Col>
                                    <div onClick={() => alteraTipoLance('fixo')} style={dadosVenda.b1 === 'fixo' ? { cursor: 'pointer', backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' } : { cursor: 'pointer', backgroundColor: '#fafafa', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={dadosVenda.b1 === 'fixo' ? { fontSize: '19px', color: 'var(--blue)' } : { fontSize: '19px', color: '#63758E' }}>Lance Fixo </div>
                                      <div style={dadosVenda.b1 === 'fixo' ? { fontSize: '12px', color: 'var(--blue)' } : { fontSize: '12px', color: '#63758E' }}>(Fixo {window.screen.width > 600 ? '-' : <br />} {dadosVenda.grupo === "67" ? "20%" : "30%"})</div>
                                    </div>

                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                  <Col sm={6} xs={6}>
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                                      <div style={{ fontSize: '22px', color: 'var(--blue)' }}>
                                        <FormControl className="inputNovo" variant="standard">
                                          <TextField
                                            disabled={dadosVenda.b1 === 'fixo' ? true : false}
                                            label="% total ofertado"
                                            InputLabelProps={{ style: { display: 'flex', color: 'var(--blue)', marginTop: '-10px' } }}
                                            className='teste'
                                            id="standard-required"
                                            variant="standard"
                                            name='b2'
                                            value={(dadosVenda.b2 === "NaN") || (dadosVenda.b2 === "0") ? "" : dadosVenda.b2}
                                            prefix="%"
                                            onChange={handleChangeInputCalculadora}
                                            inputProps={{
                                              style: { textAlign: 'center', border: 'none', alignItems: 'center', justifyContent: 'center', alignContent: 'center', color: 'var(--blue)' }
                                            }}
                                            InputProps={{

                                              endAdornment: (<InputAdornment sx={{
                                                color: 'var(--blue)',
                                              }} position="end"><div>%</div></InputAdornment>),

                                            }}



                                          />

                                        </FormControl>


                                      </div>
                                    </div>

                                  </Col>
                                  <Col sm={6} xs={6}>
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                                      <div style={{ fontSize: '22px', color: 'var(--blue)' }}>
                                        <FormControl className="inputNovo" variant="standard" >
                                          <div>
                                            <TextField
                                              disabled={dadosVenda.b1 === 'fixo' ? true : false}
                                              label={window.screen.width<600?"Total lance":"Valor total do lance"}
                                              InputLabelProps={{ shrink: (dadosVenda.t_p2 || 0) > 0 ? true : false, style: { display: 'flex', color: 'var(--blue)', marginTop: '-10px', whiteSpace:'normal', wordBreak:'break-word' } }}
                                              onChange={handleChangeInputCalculadora}
                                              className="teste"
                                              variant="standard"
                                              name="t_p2"
                                              value={(Number.isNaN(dadosVenda.t_p2)) || (dadosVenda.t_p2 === 0) ? "" : dadosVenda.t_p2}

                                              id="formatted-numberformat-input"
                                              InputProps={{
                                                inputComponent: NumberFormatCustom,
                                              }}
                                            />
                                          </div>
                                        </FormControl>


                                      </div>

                                    </div>

                                  </Col>


                                </Row>

                                <Row style={{ marginTop: "10px" }}>
                                  <Col sm={6} xs={6}>
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                                      <div style={{ fontSize: '20px', color: 'var(--blue)' }}>
                                        <FormControl className="inputNovo" variant="standard">
                                          <TextField
                                            label={window.screen.width<600?"%Lance embutido":"% de lance embutido ofertado"}
                                            InputLabelProps={{ style: { display: 'flex', color: 'var(--blue)', marginTop: '-10px' } }}
                                            className='teste'
                                            id="standard-required"
                                            variant="standard"
                                            name='b3'
                                            value={(dadosVenda.b3 === "NaN") || (dadosVenda.b3 === "0") ? "" : dadosVenda.b3}
                                            onChange={handleChangeInputCalculadora1}
                                            inputProps={{
                                              endAdornment: '%',
                                              style: { textAlign: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center', color: 'var(--blue)' }
                                            }}
                                            InputProps={{

                                              endAdornment: (<InputAdornment sx={{
                                                color: 'var(--blue)',
                                              }} position="end"><div>%</div></InputAdornment>),

                                            }}
                                          />
                                        </FormControl>


                                      </div>

                                    </div>

                                  </Col>
                                  <Col>
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                                      <div style={{ fontSize: '22px', color: 'var(--blue)' }}>
                                        <FormControl className="inputNovo" variant="standard" >
                                          <div>
                                            <TextField
                                              disabled={dadosVenda.b1 === 'fixo' ? true : false}
                                              label={window.screen.width<600?"Total embutido":'Valor total do lance Embutido'}
                                              InputLabelProps={{ shrink: (dadosVenda.t_p3 || 0) > 0 ? true : false, style: { display: 'flex', color: 'var(--blue)', marginTop: '-10px', whiteSpace:'normal', wordBreak:'break-word',  } }}
                                              onChange={handleChangeInputCalculadora1}
                                              className="teste"
                                              variant="standard"
                                              name="t_p3"
                                              value={(Number.isNaN(dadosVenda.t_p3)) || (dadosVenda.t_p3 === 0) ? "" : dadosVenda.t_p3}
                                              id="formatted-numberformat-input"
                                              InputProps={{
                                                inputComponent: NumberFormatCustom,
                                              }}
                                            />
                                          </div>
                                        </FormControl>


                                      </div>

                                    </div>

                                  </Col>
                                </Row>

                                <Row style={{ marginTop: "10px" }}>
                                  <Col sm={6} xs={6} >
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '11px', color: 'var(--blue)' }}>Recursos Próprios</div>

                                      <div style={{ color: 'var(--blue)' }} className="fonteResponsiva">
                                        {dadosVenda.b4 === "R$ NaN" ? "R$ 0" : dadosVenda.b4}


                                      </div>
                                    </div>

                                  </Col>
                                  <Col sm={6} xs={6}>
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '11px', color: 'var(--blue)' }}>Valor do Crédito</div>
                                      <div style={{ color: 'var(--blue)' }} className="fonteResponsiva" >
                                        {dadosVenda.valorCreditoF === "R$ NaN" ? "R$ 0" : dadosVenda.valorCreditoF}

                                      </div>
                                    </div>

                                  </Col>

                                </Row>

                                <Row style={{ marginTop: "10px" }}>
                                  <Col>
                                    <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                      <div style={{ fontSize: '12px', color: 'var(--blue)' }}>Valor crédito liquido com lance embutido</div>

                                      <div style={{ fontSize: '22px', color: 'var(--blue)' }}>
                                        {dadosVenda.b5 === "R$ NaN" ? "R$ 0" : dadosVenda.b5}

                                      </div>
                                    </div>

                                  </Col>

                                </Row>


                                <Row style={{ marginRight: "15" }}>
                                  <div className="botaoPesquisar" style={{ marginBottom: "20px", marginTop: "15px" }}>
                                    <Button variant="primary" className="btnPesquisar"
                                      //onClick={teste2} 
                                      style={{ marginRight: "15" }}
                                      onClick={limparCalculadora}
                                    >
                                      <Typography variant="button" gutterBottom><b>LIMPAR</b></Typography>
                                    </Button>



                                  </div>
                                </Row>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>

                    </Box>


                  </div>

                  <Typography>
                    <Tabelas1>


                      <div className="testea">
                      </div>

                    </Tabelas1>





                  </Typography>
                </> : <>
                  <div className="tabela23">
                    <Form className="form-border">
                      <Col style={{paddingTop: "10px", marginBottom: "5px"}}>
                        <div style={{ color: "var(--blue)" }}><FiArrowLeft onClick={visualizarSimulacao} className='voltar1' /></div>
                      </Col>


                      <Row>
                        <Col sm={6} xs={12}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ marginTop: 4 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="Nome Cliente *"
                                variant="standard"
                                name='emailCom1'
                                value={dadosVenda.emailCom1}
                                onChange={alteraDadosSimulacao}
                              />
                            </FormControl>
                          </div>
                        </Col>
                        <Col sm={1} xs={3}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ alignItems: 'center', marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="DDD *"
                                variant="standard"
                                name='emailCom2'
                                value={dadosVenda.emailCom2}
                                onChange={alteraDadosSimulacao}
                                InputLabelProps={{
                                  style: { alignItems: 'center' },
                                }}

                                InputProps={{
                                  sx: { "& input": { textAlign: "center" }, "& label": "center" },
                                  style: { textAlign: "end", alignItems: 'center', justifyContent: 'center', alignContent: 'center' }
                                }}

                              />
                            </FormControl>
                          </div>
                        </Col>
                        <Col sm={5} xs={9}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="Telefone *"
                                variant="standard"
                                name='emailCom3'
                                value={dadosVenda.emailCom3}
                                onChange={alteraDadosSimulacao}
                              />
                            </FormControl>
                          </div>
                        </Col>


                      </Row>
                      <Row>
                        <Col xs={12} style={{ marginBottom: '20px' }}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="E-mail *"
                                variant="standard"
                                name='emailCom4'
                                value={dadosVenda.emailCom4}
                                onChange={alteraDadosSimulacao}
                              />
                            </FormControl>
                          </div>
                        </Col>


                      </Row>



                    </Form>
                  </div>
                  <div className="tabela" style={{marginTop: '20px'}}>
                    <Row>
                      <Col sm={12} xs={12}>
                        <div style={{ color: "var(--blue)", fontSize: '20px', marginTop: '10px',marginBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Simulação de consórcio</b>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3} xs={12}>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Grupo</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.grupo==="69"?"EM FORMAÇÃO":dadosVenda.grupo} </div>
                        </div>

                      </Col>
                      <Col sm={3} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa administração</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.taxaAdministracao}%</div>
                        </div>
                      </Col>
                      <Col sm={3} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Adesão</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.adesao}%</div>
                        </div>
                      </Col>
                      <Col sm={3} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do crédito</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF}</div>
                        </div>
                      </Col>

                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col sm={3} xs={12}>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Redução</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.reducao}% </div>
                        </div>

                      </Col>
                      <Col sm={3} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Média de Lance</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.mediaLance}% </div>
                        </div>
                      </Col>
                      <Col sm={3} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo}</div>
                        </div>
                      </Col>
                      <Col sm={3} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Próxima assembleia</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.primeiraAssF} </div>
                        </div>
                      </Col>

                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col sm={6} xs={12} className="acima">
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Parcela</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaFormatado} </div>
                        </div>

                      </Col >
                      <Col sm={6} xs={12} className="acima" style={{ marginBottom: '20px' }}>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Parcela com seguro</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaComSeguroFormatado} </div>
                        </div>
                      </Col>

                    </Row>

                  </div>
                  {excluirLance ? <>
                    <div className="tabela" style={{marginTop: '15px'}}>
                      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', paddingTop: '10px' , color: "var(--blue)" }}><AiFillDelete style={{ cursor: 'pointer' }} onClick={excluirLance1} size={20} /></div>
                      <Row>

                        <div style={{ color: "var(--blue)", width: '100%', marginBottom: '10px', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Simulação Lance</b>

                        </div>

                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Percentual Lance</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.b2}% </div>
                          </div>

                        </Col>
                        <Col sm={6} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor total do lance</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{((dadosVenda.t_p2 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </div>
                          </div>
                        </Col>

                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col sm={6} xs={12}>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor lance embutido</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{((dadosVenda.t_p3 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </div>
                          </div>

                        </Col>
                        <Col sm={6} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do lance com recursos próprios</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.b4} </div>
                          </div>
                        </Col>

                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col sm={12} xs={12} style={{ marginBottom: '20px' }}>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Crédito liquido com lance embutido</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.b5} </div>
                          </div>

                        </Col>

                      </Row>


                    </div>
                  </> : <></>}
                  {excluirComparativo ? <>
                    <div className="tabela" style={{marginTop: '15px'}}>
                      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', paddingTop: '10px', color: "var(--blue)" }}><AiFillDelete style={{ cursor: 'pointer' }} onClick={excluirComparativo1} size={20} /></div>
                      <Row>

                        <div style={{ color: "var(--blue)", width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Comparativo Groscon x Financiamento</b>

                        </div>

                      </Row>

                      <div style={{ color: "var(--blue)", marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Consórcio Groscon</b>
                        <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                          {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                            dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                        </div>

                      </div>
                      



                      <Row>
                        <Col sm={3} xs={12}>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                          </div>

                        </Col>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                          </div>
                        </Col>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa de administração mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.nomePai?.replace('.', ',')}% </div>
                          </div>
                        </Col>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaFormatado} </div>
                          </div>
                        </Col>

                      </Row>

                      <div style={{ color: "var(--blue)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Financiamento</b>

                        <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                          {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                            dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                        </div>
                        <div style={{ maxWidth: `${dadosVenda.naturalCidade}`, overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ fontSize: '20px' }}>
                            {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                              dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                          </div>

                        </Col>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                          </div>
                        </Col>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.descTipo === 'IMÓVEL' ? '0,80%' : '1,50%'}</div>
                          </div>
                        </Col>
                        <Col sm={3} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.cidade} </div>
                          </div>
                        </Col>

                      </Row>

                      <div style={{ color: "var(--blue)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                        <b>Economia com o Consórcio Groscon</b>
                      </div>
                      <Row>
                        <Col sm={6} xs={12}>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.uf} </div>
                          </div>

                        </Col>
                        <Col sm={6} xs={12} className="acima">
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Total</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.apto} </div>
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col sm={12} xs={12} className="acima">
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                            <div style={{ fontSize: '120px', color: '#63758E' }}><CircularProgressWithLabel1 style={{ borderRadius: '50%' }} size={120} variant="determinate" value={dadosVenda.codBanco || 0} /> </div>
                            <div style={{ fontSize: '12px', color: '#63758E', marginTop: '-33px' }}>Economia(%)</div>
                          </div>
                        </Col>

                      </Row>
                    </div>
                  </> : <></>}

                  <div className="fab">
                    <button className="main">
                    </button>
                    <ul>
                      <li>
                        <label>Imprimir </label>
                        <button id="opcao1" onMouseDown={() => print("imprimir")}>
                          <BsPrinterFill size={20} />
                        </button>
                      </li>
                      <li>
                        <label>Download</label>
                        <button id="opcao2" onMouseDown={() => print("download")}>
                          <FaFileDownload size={20} />
                        </button>
                      </li>
                      {/*   <li>
                          <label>Salvar </label>
                          <button id="opcao1" onMouseDown={() => salvar()}>
                            <RiSave3Fill size={24} />
                          </button>
                        </li>*/}
                      {/*  <li>
      <label>Enviar email</label>
      <button id="opcao3">
      <MdEmail />
      </button>
    </li>*/}
                    </ul>
                  </div>




                </>}
              </>}


          </Typography>

        </Tabelas>


      </Container>


    </>
  )
}

export default Simulacao